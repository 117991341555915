import React, { lazy, Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from '@tanstack/react-query';
import { MainLayout, AdminLayout } from './components/Layouts';
import Home from './pages/Home';
import Categories from './pages/Categories';
import CategoryChannels from './pages/CategoryChannels';
import ChannelList from './pages/ChannelList';
import ChannelDetails from './pages/ChannelDetails';
import Login from './pages/Login';
import Register from './pages/Register';
import Blog from './pages/Blog';
import ArticleView from './pages/ArticleView';
import Dashboard from './pages/Admin/Dashboard';
import AdminCheck from './pages/Admin/AdminCheck';
import AdminCategories from './pages/Admin/Categories/index';
import CreateChannel from './pages/CreateChannel';
import ChannelSubmitted from './pages/ChannelSubmitted';
import ModerationRules from './pages/ModerationRules';
import FAQ from './pages/FAQ';
import NotFound from './pages/NotFound';
import ProtectedRoute from './components/ProtectedRoute';
import ScrollToTop from './components/ScrollToTop';
import YandexMetrika from './components/YandexMetrika';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import CreateArticle from './pages/Admin/CreateArticle';
import Contacts from './pages/Contacts';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import RemovalRequest from './pages/RemovalRequest';
import SEO from './pages/Admin/SEO';
import queryClient from './lib/queryClient';
import { refreshCsrfTokenHandler } from './utils/csrfInitializer';
import SecurityErrorDialog from './components/SecurityErrorDialog';
import { YANDEX_METRIKA_ID, ENABLE_YANDEX_METRIKA } from './config/analytics';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#1976d2',
    },
    background: {
      default: '#0a1929',
      paper: '#0a1929',
    },
  },
});

// Компонент для обновления CSRF-токена и обработки ошибок безопасности
const SecurityHandler = () => {
  const location = useLocation();
  const [securityError, setSecurityError] = useState(null);
  const { user, isAdmin } = useAuth(); // Получаем информацию о пользователе из контекста

  useEffect(() => {
    // Обновляем CSRF-токен при смене маршрута только если это админ-панель
    // или если это страницы, требующие авторизации
    try {
      const isAdminRoute = location.pathname.startsWith('/admin');
      const isAuthRoute = location.pathname.startsWith('/profile') || 
                          location.pathname.startsWith('/add-channel');
      
      // Принудительно обновляем токен только для админ-маршрутов
      const forceRefresh = isAdminRoute;
      
      // Обновляем токен с учетом типа пользователя
      refreshCsrfTokenHandler(!!user, isAdmin, forceRefresh).catch(error => {
        console.warn('Ошибка при обновлении CSRF-токена при смене маршрута:', error.message);
        // Не выбрасываем ошибку, чтобы не блокировать навигацию
      });
    } catch (error) {
      console.warn('Непредвиденная ошибка при обновлении CSRF-токена:', error.message);
    }
  }, [location.pathname, user, isAdmin]);

  // Обработчик ошибок безопасности
  useEffect(() => {
    const handleSecurityError = (event) => {
      setSecurityError({
        type: event.type === 'csrf-error' ? 'csrf' : 
              event.type === 'auth-error' ? 'session_expired' : 'unauthorized',
        message: event.detail.message
      });
    };

    // Добавляем слушатели событий
    window.addEventListener('csrf-error', handleSecurityError);
    window.addEventListener('auth-error', handleSecurityError);

    // Удаляем слушатели при размонтировании
    return () => {
      window.removeEventListener('csrf-error', handleSecurityError);
      window.removeEventListener('auth-error', handleSecurityError);
    };
  }, []);

  return (
    <>
      {securityError && (
        <SecurityErrorDialog
          open={!!securityError}
          onClose={() => setSecurityError(null)}
          errorType={securityError.type}
          message={securityError.message}
        />
      )}
    </>
  );
};

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AuthProvider>
            <Router>
              <ScrollToTop />
              <SecurityHandler />
              {ENABLE_YANDEX_METRIKA && (
                <YandexMetrika counterId={YANDEX_METRIKA_ID} />
              )}
              <Routes>
                {/* Админ панель */}
                <Route path="/admin" element={<AdminLayout />}>
                  <Route path="login" element={<AdminCheck />} />
                  <Route
                    path="dashboard"
                    element={
                      <ProtectedRoute adminRequired={true}>
                        <Dashboard />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="create-article"
                    element={
                      <ProtectedRoute adminRequired={true}>
                        <CreateArticle />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="categories"
                    element={
                      <ProtectedRoute adminRequired={true}>
                        <AdminCategories />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="seo"
                    element={
                      <ProtectedRoute adminRequired={true}>
                        <SEO />
                      </ProtectedRoute>
                    }
                  />
                </Route>

                {/* Основной сайт */}
                <Route path="/" element={<MainLayout />}>
                  <Route index element={<Home />} />
                  <Route path="categories" element={<Categories />} />
                  <Route path="category/:slug" element={<CategoryChannels />} />
                  <Route path="channels">
                    <Route index element={<ChannelList />} />
                    <Route path="category/:category" element={<CategoryChannels />} />
                  </Route>
                  
                  {/* Маршруты для каналов с унифицированным подходом */}
                  <Route path="channel/@:username" element={<ChannelDetails />} />
                  <Route path="channel/:identifier" element={<ChannelDetails />} />
                  <Route path="channel/id/:id" element={<ChannelDetails />} />
                  
                  <Route path="blog" element={<Blog />} />
                  <Route path="blog/:slug" element={<ArticleView />} />
                  <Route path="login" element={<Login />} />
                  <Route path="register" element={<Register />} />
                  <Route path="add-channel" element={
                    <ProtectedRoute>
                      <CreateChannel />
                    </ProtectedRoute>
                  } />
                  <Route path="channel-submitted" element={<ChannelSubmitted />} />
                  <Route path="moderation-rules" element={<ModerationRules />} />
                  <Route path="faq" element={<FAQ />} />
                  <Route path="contacts" element={<Contacts />} />
                  <Route path="privacy" element={<Privacy />} />
                  <Route path="terms" element={<Terms />} />
                  <Route path="removal-request" element={<RemovalRequest />} />
                  <Route path="*" element={<NotFound />} />
                </Route>
              </Routes>
            </Router>
          </AuthProvider>
        </ThemeProvider>
      </HelmetProvider>
    </QueryClientProvider>
  );
}

export default App;
