import React, { useState, useEffect } from 'react';
import { Box, Skeleton } from '@mui/material';

/**
 * Компонент для прогрессивной загрузки изображений с плейсхолдером
 * @param {string} src - URL изображения
 * @param {string} alt - Альтернативный текст
 * @param {Object} sx - Стили компонента
 */
const ProgressiveImage = ({ src, alt, sx = {} }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [error, setError] = useState(false);
  
  // Проверяем валидность URL
  const isValidSrc = src && typeof src === 'string' && !src.includes('null') && !src.includes('undefined');
  
  useEffect(() => {
    // Если URL невалидный, сразу устанавливаем ошибку
    if (!isValidSrc) {
      console.warn('ProgressiveImage: Невалидный URL изображения:', src);
      setError(true);
      setImageLoaded(false);
      return;
    }
    
    const img = new Image();
    img.src = src;
    
    img.onload = () => {
      setImageLoaded(true);
      setError(false);
    };
    
    img.onerror = () => {
      console.error('ProgressiveImage: Ошибка загрузки изображения:', src);
      setError(true);
      setImageLoaded(false);
    };

    return () => {
      img.onload = null;
      img.onerror = null;
    };
  }, [src, isValidSrc]);

  if (error || !isValidSrc) {
    // Возвращаем плейсхолдер с первой буквой
    return (
      <Box
        sx={{
          width: sx.width || 56,
          height: sx.height || 56,
          borderRadius: sx.borderRadius || 1.5,
          backgroundColor: 'primary.main',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
          fontSize: '1.75rem',
          fontWeight: 'bold',
          ...sx
        }}
      >
        {alt ? alt.charAt(0).toUpperCase() : '?'}
      </Box>
    );
  }

  return (
    <Box position="relative" sx={{ width: sx.width || 56, height: sx.height || 56 }}>
      {!imageLoaded && (
        <Skeleton
          variant="rounded"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: sx.borderRadius || 1.5
          }}
        />
      )}
      <Box
        component="img"
        src={src}
        alt={alt}
        sx={{
          opacity: imageLoaded ? 1 : 0,
          transition: 'opacity 0.3s ease-in-out',
          borderRadius: sx.borderRadius || 1.5,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          ...sx
        }}
      />
    </Box>
  );
};

export default ProgressiveImage;
