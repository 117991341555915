import CategoryIcon from '@mui/icons-material/Category';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import SchoolIcon from '@mui/icons-material/School';
import LockIcon from '@mui/icons-material/Lock';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import MovieIcon from '@mui/icons-material/Movie';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import RssFeedIcon from '@mui/icons-material/RssFeed';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import GroupIcon from '@mui/icons-material/Group';

export const statistics = [
  { 
    icon: RssFeedIcon, 
    count: '5,234', 
    label: 'Каналов',
    gradient: 'linear-gradient(120deg, #0088cc 0%, #005f8e 100%)'
  },
  { 
    icon: SmartToyIcon, 
    count: '1,876', 
    label: 'Ботов',
    gradient: 'linear-gradient(120deg, #1a237e 0%, #0d47a1 100%)'
  },
  { 
    icon: GroupIcon, 
    count: '3,542', 
    label: 'Чатов',
    gradient: 'linear-gradient(120deg, #0088cc 0%, #1a237e 100%)'
  },
  { 
    icon: WhatshotIcon, 
    count: '15,890', 
    label: 'Пользователей',
    gradient: 'linear-gradient(120deg, #005f8e 0%, #0d47a1 100%)'
  },
];

export const popularCategories = [
  { name: 'Все', count: 221331, icon: CategoryIcon },
  { name: 'Эротика', count: 28600, icon: LocalFireDepartmentIcon },
  { name: 'Новости', count: 45486, icon: NewspaperIcon, slug: 'news' },
  { name: 'Сливы 18+', count: 7760, icon: WhatshotIcon },
  { name: 'Образование', count: 13045, icon: SchoolIcon },
  { name: 'Приваты', count: 4820, icon: LockIcon }
];

export const categories = [
  { icon: NewspaperIcon, name: 'Новости', count: 1234 },
  { icon: MonetizationOnIcon, name: 'Бизнес', count: 856 },
  { icon: MovieIcon, name: 'Развлечения', count: 967 },
  { icon: SchoolIcon, name: 'Образование', count: 743 },
  { icon: SportsEsportsIcon, name: 'Игры', count: 632 },
  { icon: CategoryIcon, name: 'Другое', count: 1432 }
];

export const featuredChannels = [
  {
    id: 1,
    title: 'Tech News',
    description: 'Последние новости из мира технологий',
    subscribers: '125K',
    image: 'https://via.placeholder.com/150',
    category: 'Технологии',
    username: 'tech_news',
    avatar: 'https://via.placeholder.com/48',
    addedDate: '1 день назад'
  },
  {
    id: 2,
    title: 'Crypto Daily',
    description: 'Криптовалюты и блокчейн',
    subscribers: '89K',
    image: 'https://via.placeholder.com/150',
    category: 'Финансы',
    username: 'crypto_daily',
    avatar: 'https://via.placeholder.com/48',
    addedDate: '2 дня назад'
  },
  {
    id: 3,
    title: 'Travel Blog',
    description: 'Путешествия и приключения',
    subscribers: '234K',
    image: 'https://via.placeholder.com/150',
    category: 'Путешествия',
    username: 'travel_blog',
    avatar: 'https://via.placeholder.com/48',
    addedDate: '3 дня назад'
  },
  {
    id: 4,
    title: 'Gaming News',
    description: 'Новости из мира игр',
    subscribers: '178K',
    image: 'https://via.placeholder.com/150',
    category: 'Игры',
    username: 'gaming_news',
    avatar: 'https://via.placeholder.com/48',
    addedDate: '4 дня назад'
  },
  {
    id: 5,
    title: 'Cooking Master',
    description: 'Рецепты и кулинарные советы',
    subscribers: '321K',
    image: 'https://via.placeholder.com/150',
    category: 'Кулинария',
    username: 'cooking_master',
    avatar: 'https://via.placeholder.com/48',
    addedDate: '5 дней назад'
  },
  {
    id: 6,
    title: 'Science Today',
    description: 'Научные открытия и исследования',
    subscribers: '156K',
    image: 'https://via.placeholder.com/150',
    category: 'Наука',
    username: 'science_today',
    avatar: 'https://via.placeholder.com/48',
    addedDate: '6 дней назад'
  },
  {
    id: 7,
    title: 'Fitness Tips',
    description: 'Советы по фитнесу и здоровью',
    subscribers: '289K',
    image: 'https://via.placeholder.com/150',
    category: 'Фитнес',
    username: 'fitness_tips',
    avatar: 'https://via.placeholder.com/48',
    addedDate: '7 дней назад'
  },
  {
    id: 8,
    title: 'Movie Reviews',
    description: 'Обзоры фильмов и сериалов',
    subscribers: '198K',
    image: 'https://via.placeholder.com/150',
    category: 'Кино',
    username: 'movie_reviews',
    avatar: 'https://via.placeholder.com/48',
    addedDate: '8 дней назад'
  }
];

export const recentChannels = [
  {
    id: 9,
    title: 'AI Updates',
    description: 'Искусственный интеллект и ML',
    subscribers: '45K',
    image: 'https://via.placeholder.com/150',
    addedDate: '2 часа назад',
    category: 'Технологии',
    username: 'ai_updates',
    avatar: 'https://via.placeholder.com/48'
  },
  {
    id: 10,
    title: 'Digital Art',
    description: 'Цифровое искусство и дизайн',
    subscribers: '78K',
    image: 'https://via.placeholder.com/150',
    addedDate: '3 часа назад',
    category: 'Искусство',
    username: 'digital_art',
    avatar: 'https://via.placeholder.com/48'
  },
  {
    id: 11,
    title: 'Book Club',
    description: 'Обсуждение книг и литературы',
    subscribers: '56K',
    image: 'https://via.placeholder.com/150',
    addedDate: '4 часа назад',
    category: 'Литература',
    username: 'book_club',
    avatar: 'https://via.placeholder.com/48'
  },
  {
    id: 12,
    title: 'Music News',
    description: 'Новости музыкальной индустрии',
    subscribers: '134K',
    image: 'https://via.placeholder.com/150',
    addedDate: '5 часов назад',
    category: 'Музыка',
    username: 'music_news',
    avatar: 'https://via.placeholder.com/48'
  },
  {
    id: 13,
    title: 'Pet Care',
    description: 'Советы по уходу за питомцами',
    subscribers: '89K',
    image: 'https://via.placeholder.com/150',
    addedDate: '6 часов назад',
    category: 'Животные',
    username: 'pet_care',
    avatar: 'https://via.placeholder.com/48'
  },
  {
    id: 14,
    title: 'Fashion Trends',
    description: 'Тренды моды и стиля',
    subscribers: '112K',
    image: 'https://via.placeholder.com/150',
    addedDate: '7 часов назад',
    category: 'Мода',
    username: 'fashion_trends',
    avatar: 'https://via.placeholder.com/48'
  },
  {
    id: 15,
    title: 'DIY Projects',
    description: 'Проекты своими руками',
    subscribers: '167K',
    image: 'https://via.placeholder.com/150',
    addedDate: '8 часов назад',
    category: 'DIY',
    username: 'diy_projects',
    avatar: 'https://via.placeholder.com/48'
  },
  {
    id: 16,
    title: 'Space News',
    description: 'Новости космоса и астрономии',
    subscribers: '145K',
    image: 'https://via.placeholder.com/150',
    addedDate: '9 часов назад',
    category: 'Космос',
    username: 'space_news',
    avatar: 'https://via.placeholder.com/48'
  }
];

export const searchCategories = [
  { id: 1, label: 'Сливы блогерш', color: '#ff1744' },
  { id: 2, label: 'Эротика', color: '#ff3d00' },
  { id: 3, label: 'Приваты', color: '#d500f9' },
];

export const popularSearches = [
  'Эротические каналы',
  'Премиум контент',
  'Знакомства 18+',
];
