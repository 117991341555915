import React, { useEffect, useState } from 'react';
import { 
  Box, 
  Chip,
  alpha
} from '@mui/material';
import CategoryIcon from '@mui/icons-material/Category';
import axios from 'axios';
import { getCache, setCache } from '../../services/cacheService';

/**
 * Компонент метаданных для страницы деталей канала
 * 
 * @param {Object} props - Свойства компонента
 * @param {Object} props.channel - Данные канала
 * @param {Function} props.navigate - Функция для навигации
 * @returns {JSX.Element} Компонент метаданных канала
 */
const DetailMetadata = ({ channel, navigate }) => {
  // Проверяем, является ли канал приватным
  const isPrivate = !channel.username;
  // Состояние для хранения информации о категориях
  const [categoriesInfo, setCategoriesInfo] = useState({});
  
  // Загружаем информацию о категориях при монтировании компонента
  useEffect(() => {
    const CACHE_KEY = 'categories_info';
    const CACHE_TTL = 60 * 60 * 1000; // 1 час
    
    const fetchCategories = async () => {
      try {
        // Проверяем наличие данных в кэше
        const cachedData = getCache(CACHE_KEY);
        if (cachedData) {
          setCategoriesInfo(cachedData);
          return;
        }
        
        // Если данных в кэше нет, загружаем с сервера
        const response = await axios.get('/api/categories');
        if (response.data && Array.isArray(response.data)) {
          // Создаем объект для быстрого доступа к информации о категориях по slug
          const categoriesMap = {};
          response.data.forEach(category => {
            categoriesMap[category.slug] = {
              name: category.name,
              slug: category.slug
            };
          });
          
          // Сохраняем в кэш и в состояние
          setCache(CACHE_KEY, categoriesMap, CACHE_TTL);
          setCategoriesInfo(categoriesMap);
        }
      } catch (error) {
        console.error('Ошибка при загрузке категорий:', error);
      }
    };
    
    fetchCategories();
  }, []);
  
  return (
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: 2
    }}>
      {/* Категории */}
      <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
        {channel.categories?.map((categorySlug) => (
          <Chip
            key={categorySlug}
            icon={<CategoryIcon />}
            label={categoriesInfo[categorySlug]?.name || categorySlug}
            onClick={() => navigate('/categories')}
            clickable
            sx={{
              bgcolor: theme => alpha(theme.palette.primary.main, 0.06),
              border: theme => `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
              color: 'primary.main',
              transition: 'all 0.3s ease-in-out',
              '&:hover': {
                bgcolor: theme => alpha(theme.palette.primary.main, 0.1),
                transform: 'translateY(-2px)',
                boxShadow: theme => `0 4px 8px ${alpha(theme.palette.primary.main, 0.15)}`
              },
              '& .MuiChip-icon': {
                color: 'primary.main',
                transition: 'transform 0.3s ease-in-out',
              },
              '&:hover .MuiChip-icon': {
                transform: 'rotate(15deg)'
              }
            }}
          />
        ))}
      </Box>

      {/* Тип канала и доступность */}
      <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
        {/* Показываем тип канала только если канал не приватный */}
        {!isPrivate && (
          <Chip
            label={channel.type === 'bot' ? 'Бот' : channel.type === 'chat' ? 'Чат' : 'Канал'}
            sx={{
              bgcolor: theme => alpha(theme.palette.primary.main, 0.06),
              border: theme => `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
              color: 'primary.main',
              transition: 'all 0.3s ease-in-out',
              '&:hover': {
                bgcolor: theme => alpha(theme.palette.primary.main, 0.1),
                transform: 'translateY(-2px)'
              }
            }}
          />
        )}
        {channel.username ? (
          <Chip
            label={`@${channel.username}`}
            component="a"
            href={`https://t.me/${channel.username}`}
            target="_blank"
            clickable
            sx={{
              bgcolor: 'background.paper',
              border: theme => `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
              color: 'primary.main',
              transition: 'all 0.3s ease-in-out',
              '&:hover': {
                bgcolor: theme => alpha(theme.palette.primary.main, 0.1),
                transform: 'translateY(-2px)',
                boxShadow: theme => `0 4px 8px ${alpha(theme.palette.primary.main, 0.15)}`
              }
            }}
          />
        ) : (
          <Chip
            label="Приватный канал"
            sx={{
              bgcolor: theme => alpha(theme.palette.error.main, 0.06),
              border: theme => `1px solid ${alpha(theme.palette.error.main, 0.1)}`,
              color: 'error.main',
              transition: 'all 0.3s ease-in-out',
              '&:hover': {
                bgcolor: theme => alpha(theme.palette.error.main, 0.1),
                transform: 'translateY(-2px)'
              }
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default DetailMetadata;
