import React, { useState, useEffect } from 'react';
import {
  Autocomplete,
  TextField,
  Chip,
  Box,
  Typography,
  Paper,
  useTheme,
  alpha,
  CircularProgress
} from '@mui/material';
import CategoryIcon from '@mui/icons-material/Category';
import axios from 'axios';

/**
 * Компонент выбора категорий
 * Загружает активные категории из базы данных и позволяет выбрать одну или несколько
 */
const CategorySelect = ({ value, onChange, error, helperText }) => {
  const theme = useTheme();
  const [inputValue, setInputValue] = useState('');
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  // Загрузка категорий из API
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setLoading(true);
        const response = await axios.get('/api/categories/active');
        // Фильтруем категории, исключая категорию "all"
        const filteredCategories = response.data
          .filter(category => category.slug !== 'all' && category.isActive)
          .map(category => category.name);
        setCategories(filteredCategories);
      } catch (error) {
        console.error('Ошибка при загрузке категорий:', error);
        // Резервный список категорий на случай ошибки
        setCategories([
          'IT сфера', 'Авто и мото', 'Азартные игры', 'Анекдоты', 'Аниме', 
          'Бизнес и финансы', 'Блогеры и знаменитости', 'Вакансии и работа', 
          'Дизайн', 'Для родителей', 'Здоровье', 'Игры', 'Криптовалюты', 
          'Кулинария', 'Курсы', 'Лайфхаки', 'Литература', 'Личные блоги', 
          'Мода и красота', 'Музыка', 'Наука и технологии', 'Новости', 
          'Образование', 'Политика', 'Приколы', 'Природа и животные', 
          'Психология', 'Путешествия', 'Спорт', 'Фильмы и сериалы'
        ]);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  return (
    <Autocomplete
      multiple
      options={categories}
      value={value}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      loading={loading}
      freeSolo
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            key={index}
            label={option}
            {...getTagProps({ index })}
            sx={{
              bgcolor: theme => alpha(theme.palette.primary.main, 0.1),
              borderRadius: '6px',
              '& .MuiChip-deleteIcon': {
                color: 'primary.main',
              },
            }}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Категории"
          placeholder={loading ? 'Загрузка категорий...' : 'Выберите категории'}
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              backgroundColor: '#ffffff',
              '& fieldset': {
                borderColor: error ? 'error.main' : 'rgba(0, 0, 0, 0.23)',
                borderWidth: '1px',
              },
              '&:hover fieldset': {
                borderColor: error ? 'error.main' : 'rgba(0, 0, 0, 0.87)',
              },
              '&.Mui-focused fieldset': {
                borderColor: error ? 'error.main' : 'primary.main',
                borderWidth: '2px',
              }
            },
            '& .MuiInputLabel-root': {
              color: 'rgba(0, 0, 0, 0.7)',
              '&.Mui-focused': {
                color: error ? 'error.main' : 'primary.main',
              }
            },
            '& .MuiChip-root': {
              backgroundColor: theme => alpha(theme.palette.primary.main, 0.1),
              color: 'rgba(0, 0, 0, 0.87)',
              borderRadius: '6px',
              '& .MuiChip-deleteIcon': {
                color: 'rgba(0, 0, 0, 0.87)',
                '&:hover': {
                  color: 'primary.main',
                }
              },
            },
            '& input': {
              color: 'rgba(0, 0, 0, 0.87)',
              '&::placeholder': {
                color: 'rgba(0, 0, 0, 0.6)',
                opacity: 1,
              },
            },
          }}
        />
      )}
      renderOption={(props, option) => (
        <Box
          component="li"
          {...props}
          sx={{
            '&.MuiAutocomplete-option': {
              padding: '8px 16px',
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              '&:hover': {
                bgcolor: theme => alpha(theme.palette.primary.main, 0.08),
              },
              '&[aria-selected="true"]': {
                bgcolor: theme => alpha(theme.palette.primary.main, 0.12),
                '&:hover': {
                  bgcolor: theme => alpha(theme.palette.primary.main, 0.16),
                },
              },
            },
          }}
        >
          <CategoryIcon
            sx={{
              color: 'primary.main',
              fontSize: '1.25rem',
            }}
          />
          <Typography variant="body2">{option}</Typography>
        </Box>
      )}
      PopperComponent={({ style, ...props }) => (
        <Paper
          elevation={8}
          {...props}
          style={{ ...style, boxShadow: '0 4px 20px rgba(0,0,0,0.1)', borderRadius: '8px' }}
        />
      )}
      noOptionsText="Категории не найдены"
      loadingText="Загрузка категорий..."
    />
  );
};

export default CategorySelect;
