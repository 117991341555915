import React from 'react';
import { 
  Box, 
  Typography,
  alpha
} from '@mui/material';

/**
 * Компонент описания для страницы деталей канала
 * 
 * @param {Object} props - Свойства компонента
 * @param {Object} props.channel - Данные канала
 * @returns {JSX.Element} Компонент описания канала
 */
const DetailDescription = ({ channel }) => {
  return (
    <Box 
      sx={{ 
        p: 2.5,
        mb: 3,
        borderRadius: 2,
        bgcolor: theme => alpha(theme.palette.primary.main, 0.03),
        border: theme => `1px solid ${alpha(theme.palette.primary.main, 0.08)}`,
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
          bgcolor: theme => alpha(theme.palette.primary.main, 0.05),
          transform: 'translateY(-2px)',
          boxShadow: theme => `0 4px 12px ${alpha(theme.palette.primary.main, 0.08)}`
        }
      }}
    >
      <Typography 
        variant="body1" 
        sx={{ 
          color: 'text.primary',
          whiteSpace: 'pre-wrap',
          lineHeight: 1.7,
          fontSize: { xs: '0.9375rem', sm: '1rem' },
          letterSpacing: '0.015em',
          fontWeight: 400,
          WebkitFontSmoothing: 'antialiased',
          MozOsxFontSmoothing: 'grayscale',
          textRendering: 'optimizeLegibility',
          '& strong': {
            fontWeight: 600
          }
        }}
      >
        {channel.description}
      </Typography>
    </Box>
  );
};

export default DetailDescription;
