import React from 'react';
import {
  Paper,
  Typography,
  Box,
  Button,
  Avatar,
  alpha,
} from '@mui/material';
import { WorkspacePremium as WorkspacePremiumIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const PremiumChannelPromo = ({ onClick }) => {
  const navigate = useNavigate();
  
  const handleButtonClick = (e) => {
    e.stopPropagation(); // Предотвращаем всплытие события, чтобы не сработал onClick родительского компонента
    navigate('/faq?scroll=form'); // Переход на страницу FAQ с прокруткой к форме
  };
  
  return (
    <Paper
      elevation={0}
      onClick={onClick}
      sx={{
        p: 2.5,
        height: '100%',
        border: '1px solid',
        borderColor: (theme) => `${alpha(theme.palette.warning.main, 0.5)}`,
        background: (theme) => `linear-gradient(135deg, ${alpha(theme.palette.warning.main, 0.05)} 0%, ${alpha(theme.palette.warning.main, 0.1)} 100%)`,
        transition: 'all 0.2s',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        overflow: 'hidden',
        cursor: 'pointer',
        '&:hover': {
          borderColor: 'warning.main',
          transform: 'translateY(-2px)',
          boxShadow: (theme) => `0 4px 20px ${alpha(theme.palette.warning.main, 0.2)}`,
        },
      }}
    >
      <Box sx={{ position: 'relative', zIndex: 1, height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', mb: 2 }}>
          <Avatar
            sx={{
              width: 64,
              height: 64,
              borderRadius: 2,
              mr: 2,
              bgcolor: (theme) => alpha(theme.palette.warning.main, 0.2),
              transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
              '&:hover': {
                transform: 'scale(1.05)',
                boxShadow: (theme) => `0 0 20px ${alpha(theme.palette.warning.main, 0.3)}`,
              }
            }}
          >
            <WorkspacePremiumIcon sx={{ 
              fontSize: '2rem', 
              color: 'warning.main',
              transition: 'transform 0.2s ease-in-out',
              '&:hover': {
                transform: 'scale(1.1)'
              }
            }} />
          </Avatar>

          <Box sx={{ flex: 1 }}>
            <Typography
              variant="h6"
              component="h3"
              sx={{
                fontWeight: 600,
                mb: 0.5,
                lineHeight: 1.2,
              }}
            >
              Премиум место
            </Typography>
            <Typography
              component="div"
              variant="body2"
              sx={{
                color: 'warning.main',
                bgcolor: theme => alpha(theme.palette.warning.main, 0.1),
                py: 0.5,
                px: 1,
                borderRadius: 1,
                fontSize: '0.75rem',
                fontWeight: 500,
                display: 'inline-block',
              }}
            >
              @your_channel
            </Typography>
          </Box>
        </Box>

        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            mb: 2,
            flexGrow: 1,
            display: '-webkit-box',
            WebkitLineClamp: 3,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          Разместите здесь свой канал и получите максимум внимания.
        </Typography>

        <Button
          variant="contained"
          fullWidth
          onClick={handleButtonClick}
          sx={{
            textTransform: 'none',
            borderRadius: '8px',
            bgcolor: 'warning.main',
            color: 'warning.contrastText',
            boxShadow: (theme) => `0 4px 12px ${alpha(theme.palette.warning.main, 0.4)}`,
            '&:hover': {
              bgcolor: 'warning.dark',
              boxShadow: (theme) => `0 4px 16px ${alpha(theme.palette.warning.main, 0.5)}`,
            },
          }}
        >
          Занять место
        </Button>
      </Box>
    </Paper>
  );
};

export default PremiumChannelPromo;
