import React, { useState } from 'react';
import {
  AppBar,
  Box,
  Toolbar,
  Button,
  Container,
  useScrollTrigger,
  Slide,
  useTheme,
  useMediaQuery,
  IconButton,
  Tooltip,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import CategoryIcon from '@mui/icons-material/Category';
import ArticleIcon from '@mui/icons-material/Article';
import HelpIcon from '@mui/icons-material/Help';
import CloseIcon from '@mui/icons-material/Close';
import { useAuth } from '../contexts/AuthContext';
import logo from '../assets/images/logo.svg';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: '#1e293b',
  backdropFilter: 'blur(10px)',
  boxShadow: 'none',
  borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
  padding: 0,
  zIndex: theme.zIndex.drawer + 1,
  position: 'sticky',
  top: 0
}));

const BrandContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  marginRight: theme.spacing(4),
  position: 'relative'
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '32px',
  height: '32px',
  flexShrink: 0,
  position: 'relative',
  [theme.breakpoints.up('sm')]: {
    width: '36px',
    height: '36px',
  },
  '& img': {
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    transition: 'all 0.3s ease',
    filter: 'drop-shadow(0 2px 4px rgba(0,136,204,0.15))'
  },
  '&:hover img': {
    filter: 'drop-shadow(0 4px 8px rgba(0,136,204,0.3))'
  },
}));

const BrandName = styled(Typography)(({ theme }) => ({
  fontSize: '1.2rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.5rem',
  },
  fontWeight: 800,
  marginLeft: theme.spacing(1.5),
  letterSpacing: '0.5px',
  textTransform: 'uppercase',
  background: 'linear-gradient(135deg, #0088cc 0%, #33a1d6 100%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  position: 'relative',
  whiteSpace: 'nowrap',
  display: 'block',
  '&::before': {
    content: '""',
    position: 'absolute',
    inset: -1,
    background: 'linear-gradient(135deg, rgba(0,136,204,0.15), transparent)',
    borderRadius: '4px',
    filter: 'blur(8px)',
    opacity: 0,
    transition: 'opacity 0.3s ease'
  },
  '&:hover::before': {
    opacity: 1
  }
}));

const NavButton = styled(Button)(({ theme, active }) => ({
  color: active ? '#0088cc' : 'rgba(255, 255, 255, 0.8)',
  fontWeight: active ? 600 : 500,
  fontSize: '1rem',
  margin: theme.spacing(0, 2),
  position: 'relative',
  overflow: 'hidden',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '2px',
    background: 'linear-gradient(90deg, #0088cc, #005580)',
    transform: !active ? 'translateX(-100%)' : 'translateX(-100%)',
    transition: 'transform 0.3s ease'
  },
  '&:hover': {
    color: '#0088cc',
    background: 'transparent',
    '&::after': {
      transform: 'translateX(0)'
    }
  },
}));

const AddChannelButton = styled(Button)(({ theme }) => ({
  color: '#ffffff',
  background: 'linear-gradient(135deg, #0088cc, #005580)',
  borderRadius: '10px',
  fontWeight: 600,
  padding: theme.spacing(1, 3),
  marginRight: theme.spacing(2),
  transition: 'all 0.3s ease',
  textTransform: 'none',
  fontSize: '0.95rem',
  boxShadow: '0 4px 15px rgba(0, 136, 204, 0.2)',
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(135deg, rgba(255,255,255,0.1), transparent)',
    transition: 'transform 0.3s ease',
    transform: 'translateX(-100%)'
  },
  '&:hover': {
    boxShadow: '0 6px 20px rgba(0, 136, 204, 0.3)',
    transform: 'translateY(-1px)',
    '&::before': {
      transform: 'translateX(0)'
    }
  },
  '& .MuiSvgIcon-root': {
    fontSize: '1.2rem',
    marginRight: theme.spacing(1),
    transition: 'transform 0.3s ease'
  },
  '&:hover .MuiSvgIcon-root': {
    transform: 'rotate(90deg)'
  }
}));

const AuthButton = styled(Button)(({ theme }) => ({
  color: '#0088cc',
  background: 'rgba(0, 136, 204, 0.1)',
  border: '2px solid #0088cc',
  borderRadius: '10px',
  fontWeight: 600,
  padding: theme.spacing(0.8, 3),
  transition: 'all 0.3s ease',
  textTransform: 'none',
  fontSize: '0.95rem',
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(135deg, rgba(255,255,255,0.1), transparent)',
    transition: 'transform 0.3s ease',
    transform: 'translateX(-100%)'
  },
  '&:hover': {
    background: 'rgba(0, 136, 204, 0.2)',
    borderColor: '#0088cc',
    color: '#0088cc',
    '&::before': {
      transform: 'translateX(0)'
    }
  },
  '& .MuiSvgIcon-root': {
    fontSize: '1.2rem',
    marginRight: theme.spacing(1),
    transition: 'transform 0.3s ease'
  },
  '&:hover .MuiSvgIcon-root': {
    transform: 'scale(1.1)'
  }
}));

const StyledLogoutButton = styled(IconButton)(({ theme }) => ({
  color: 'rgba(255, 255, 255, 0.6)',
  padding: theme.spacing(1),
  transition: 'all 0.3s ease',
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    inset: 4,
    border: '2px solid transparent',
    borderRadius: '50%',
    background: 'linear-gradient(135deg, rgba(204,0,0,0.2), transparent) border-box',
    WebkitMask: 'linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)',
    WebkitMaskComposite: 'xor',
    maskComposite: 'exclude',
    opacity: 0,
    transition: 'opacity 0.3s ease'
  },
  '&:hover': {
    color: '#cc0000',
    transform: 'rotate(90deg)',
    '&::before': {
      opacity: 1
    }
  },
}));

const StyledMenuButton = styled(IconButton)(({ theme }) => ({
  color: 'rgba(255, 255, 255, 0.8)',
  padding: theme.spacing(1),
  '&:hover': {
    color: '#0088cc'
  }
}));

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: '85%',
    maxWidth: '320px',
    background: 'rgba(255, 255, 255, 0.98)',
    backdropFilter: 'blur(10px)',
    borderRight: '1px solid rgba(0,136,204,0.08)',
  },
}));

const DrawerHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  borderBottom: '1px solid rgba(0,136,204,0.08)',
  background: 'linear-gradient(135deg, rgba(0,136,204,0.03) 0%, transparent 100%)'
}));

const HideOnScroll = ({ children }) => {
  const trigger = useScrollTrigger();
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

const Header = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const { isAuthenticated, logout } = useAuth();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = (event) => {
    event.preventDefault();
    logout();
  };

  const navItems = [
    { title: 'Главная', path: '/', icon: <HomeIcon /> },
    { title: 'Каталог', path: '/categories', icon: <CategoryIcon /> },
    { title: 'Блог', path: '/blog', icon: <ArticleIcon /> },
    { title: 'О нас', path: '/faq', icon: <HelpIcon /> },
  ];

  const drawer = (
    <Box>
      <DrawerHeader>
        <BrandContainer 
          component={RouterLink} 
          to="/"
          onClick={handleDrawerToggle}
        >
          <LogoContainer>
            <img src={logo} alt="TeleHunt Logo" />
          </LogoContainer>
          <BrandName variant="h1" sx={{ fontSize: { xs: '1.4rem', sm: '1.6rem' } }}>
            TeleHunt
          </BrandName>
        </BrandContainer>
        <IconButton onClick={handleDrawerToggle}>
          <CloseIcon />
        </IconButton>
      </DrawerHeader>
      
      <List sx={{ p: 2 }}>
        {navItems.map((item, index) => (
          <React.Fragment key={item.title}>
            <ListItem disablePadding>
              <ListItemButton
                component={RouterLink}
                to={item.path}
                onClick={handleDrawerToggle}
                sx={{
                  py: 2,
                  color: location.pathname === item.path ? '#0088cc' : '#2a2a2a',
                  borderRadius: '8px',
                  '&:hover': {
                    backgroundColor: 'rgba(0,136,204,0.08)',
                  },
                }}
              >
                <ListItemIcon sx={{ 
                  color: location.pathname === item.path ? '#0088cc' : '#666666',
                  minWidth: '40px'
                }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText 
                  primary={item.title}
                  primaryTypographyProps={{
                    fontWeight: location.pathname === item.path ? 600 : 500
                  }}
                />
              </ListItemButton>
            </ListItem>
            {index < navItems.length - 1 && (
              <Box sx={{ mx: 2, my: 1, borderTop: '1px solid rgba(0,0,0,0.08)' }} />
            )}
          </React.Fragment>
        ))}
      </List>
      
      {isAuthenticated ? (
        <Box sx={{ p: 2, mt: 'auto' }}>
          <AddChannelButton
            fullWidth
            startIcon={<AddIcon />}
            component={RouterLink}
            to="/add-channel"
            onClick={handleDrawerToggle}
          >
            Добавить канал
          </AddChannelButton>
          <Button
            fullWidth
            onClick={(e) => {
              handleLogout(e);
              handleDrawerToggle();
            }}
            sx={{ 
              mt: 2,
              color: '#cc0000',
              borderColor: '#cc0000',
              '&:hover': {
                borderColor: '#aa0000',
                backgroundColor: 'rgba(204,0,0,0.08)'
              }
            }}
            variant="outlined"
            startIcon={<LogoutIcon />}
          >
            Выйти
          </Button>
        </Box>
      ) : (
        <Box sx={{ p: 2, mt: 'auto' }}>
          <AuthButton
            fullWidth
            startIcon={<LoginIcon />}
            component={RouterLink}
            to="/login"
            onClick={handleDrawerToggle}
            sx={{ 
              background: 'rgba(0, 136, 204, 0.1)',
              borderColor: '#0088cc',
              color: '#0088cc',
              fontWeight: 600,
              '&:hover': {
                background: 'rgba(0, 136, 204, 0.2)',
                borderColor: '#0088cc',
              }
            }}
          >
            Войти
          </AuthButton>
        </Box>
      )}
    </Box>
  );

  return (
    <>
      <HideOnScroll>
        <StyledAppBar>
          <Container maxWidth="lg" sx={{ overflow: 'hidden' }}>
            <Toolbar 
              disableGutters 
              sx={{ 
                minHeight: { xs: '64px', sm: '85px' },
                px: { xs: 1, sm: 2 },
                display: 'flex',
                justifyContent: 'space-between',
                position: 'relative'
              }}
            >
              {/* Левая часть - логотип и бургер-меню */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  zIndex: 2
                }}
              >
                {isMobile && (
                  <StyledMenuButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ 
                      color: '#ffffff',
                      '&:hover': {
                        color: '#0088cc'
                      },
                      mr: 1
                    }}
                  >
                    <MenuIcon />
                  </StyledMenuButton>
                )}

                <BrandContainer 
                  component={RouterLink} 
                  to="/"
                >
                  <LogoContainer>
                    <img src={logo} alt="TeleHunt Logo" />
                  </LogoContainer>
                  <BrandName variant="h1">TeleHunt</BrandName>
                </BrandContainer>
              </Box>

              {/* Центральная часть - меню */}
              {!isMobile && (
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  gap: 2,
                  position: 'absolute',
                  left: '50%',
                  transform: 'translateX(-50%)'
                }}>
                  <NavButton
                    component={RouterLink}
                    to="/"
                    active={location.pathname === '/'}
                  >
                    Главная
                  </NavButton>
                  <NavButton
                    component={RouterLink}
                    to="/categories"
                    active={location.pathname === '/categories'}
                  >
                    Каталог
                  </NavButton>
                  <NavButton
                    component={RouterLink}
                    to="/blog"
                    active={location.pathname === '/blog'}
                  >
                    Блог
                  </NavButton>
                  <NavButton
                    component={RouterLink}
                    to="/faq"
                    active={location.pathname === '/faq'}
                  >
                    О нас
                  </NavButton>
                </Box>
              )}

              {/* Правая часть - кнопки авторизации */}
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: 1,
                zIndex: 2
              }}>
                {!isMobile && isAuthenticated && (
                  <AddChannelButton
                    startIcon={<AddIcon />}
                    component={RouterLink}
                    to="/add-channel"
                  >
                    Добавить канал
                  </AddChannelButton>
                )}
                
                {!isMobile && !isAuthenticated && (
                  <AuthButton
                    startIcon={<LoginIcon />}
                    component={RouterLink}
                    to="/login"
                  >
                    Войти
                  </AuthButton>
                )}

                {!isMobile && isAuthenticated && (
                  <Tooltip title="Выйти">
                    <StyledLogoutButton onClick={handleLogout}>
                      <LogoutIcon />
                    </StyledLogoutButton>
                  </Tooltip>
                )}
              </Box>
            </Toolbar>
          </Container>
        </StyledAppBar>
      </HideOnScroll>

      <StyledDrawer
        variant="temporary"
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
        }}
      >
        {drawer}
      </StyledDrawer>
    </>
  );
};

export default Header;
