import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // Данные считаются свежими в течение 5 минут
      cacheTime: 1000 * 60 * 30, // Кэш хранится 30 минут
      refetchOnWindowFocus: false, // Отключаем автоматическое обновление при фокусе окна
      retry: (failureCount, error) => {
        // Не повторяем запрос при 401 и 403 ошибках
        if (error?.response?.status === 401 || error?.response?.status === 403) {
          return false;
        }
        return failureCount < 2;
      }
    },
  },
});

export default queryClient;
