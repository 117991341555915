/**
 * Конфигурация аналитических сервисов
 */

/**
 * ID счетчика Яндекс.Метрики
 * Берется из переменной окружения REACT_APP_YANDEX_METRIKA_ID
 * @type {string}
 */
export const YANDEX_METRIKA_ID = process.env.REACT_APP_YANDEX_METRIKA_ID || '';

/**
 * Флаг, указывающий, включена ли Яндекс.Метрика
 * Включена только если указан ID счетчика (для тестирования в режиме разработки)
 * @type {boolean}
 */
export const ENABLE_YANDEX_METRIKA = !!YANDEX_METRIKA_ID;

/**
 * Настройки Яндекс.Метрики
 * @type {Object}
 */
export const YANDEX_METRIKA_OPTIONS = {
  clickmap: true,
  trackLinks: true,
  accurateTrackBounce: true,
  webvisor: true,
  ecommerce: false,
};

/**
 * Функция для отправки события в Яндекс.Метрику
 * @param {string} eventName - Название события
 * @param {Object} [params] - Параметры события
 */
export const sendYandexMetrikaEvent = (eventName, params = {}) => {
  if (window.ym && ENABLE_YANDEX_METRIKA && YANDEX_METRIKA_ID) {
    window.ym(YANDEX_METRIKA_ID, 'reachGoal', eventName, params);
  }
};

/**
 * Хук для отслеживания просмотра страницы
 * Используется в компонентах, которые не имеют доступа к компоненту YandexMetrika
 * @param {string} path - Путь страницы
 */
export const trackPageView = (path) => {
  if (window.ym && ENABLE_YANDEX_METRIKA && YANDEX_METRIKA_ID) {
    window.ym(YANDEX_METRIKA_ID, 'hit', path);
  }
};
