import React, { useState, useEffect, useMemo } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  TextField, 
  InputAdornment,
  Grid, 
  Box, 
  Typography, 
  Tabs, 
  Tab, 
  Tooltip,
  Paper,
  IconButton,
  Divider,
  useTheme,
  alpha
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { iconRegistry, iconGroups, getAllIcons } from '../../utils/iconRegistry';

/**
 * Компонент для выбора иконки в админке
 * 
 * @param {Object} props - Свойства компонента
 * @param {boolean} props.open - Флаг открытия диалога
 * @param {function} props.onClose - Функция закрытия диалога
 * @param {function} props.onSelect - Функция выбора иконки (получает название иконки)
 * @param {string} props.selectedIcon - Текущая выбранная иконка
 * @returns {JSX.Element}
 */
const IconSelector = ({ open, onClose, onSelect, selectedIcon }) => {
  const theme = useTheme();
  const [searchQuery, setSearchQuery] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [hoveredIcon, setHoveredIcon] = useState(null);

  // Сбрасываем поиск при открытии диалога
  useEffect(() => {
    if (open) {
      setSearchQuery('');
    }
  }, [open]);

  // Получаем все иконки
  const allIcons = useMemo(() => getAllIcons(), []);

  // Фильтруем иконки по поисковому запросу
  const filteredIcons = useMemo(() => {
    if (!searchQuery) return [];
    
    const query = searchQuery.toLowerCase();
    return allIcons.filter(icon => 
      icon.name.toLowerCase().includes(query)
    );
  }, [searchQuery, allIcons]);

  // Определяем, какие иконки показывать в зависимости от активной вкладки и поиска
  const displayedIcons = useMemo(() => {
    if (searchQuery) {
      return filteredIcons;
    }
    
    // Если выбрана вкладка "Все иконки"
    if (activeTab === 0) {
      return allIcons;
    }
    
    // Иначе показываем иконки из выбранной группы
    const groupName = iconGroups[activeTab - 1].name;
    return iconGroups[activeTab - 1].icons.map(iconName => ({
      name: iconName,
      component: iconRegistry[iconName]
    }));
  }, [activeTab, searchQuery, filteredIcons, allIcons]);

  // Обработчик выбора иконки
  const handleSelectIcon = (iconName) => {
    onSelect(iconName);
    onClose();
  };

  // Обработчик изменения вкладки
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setSearchQuery(''); // Сбрасываем поиск при смене вкладки
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      maxWidth="md" 
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          overflow: 'hidden'
        }
      }}
    >
      <DialogTitle sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        bgcolor: 'background.paper',
        borderBottom: '1px solid',
        borderColor: 'divider',
        py: 2
      }}>
        <Typography variant="h6" component="div">
          Выбор иконки
        </Typography>
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <Box sx={{ 
        px: 3, 
        pt: 2, 
        pb: 1,
        bgcolor: alpha(theme.palette.primary.main, 0.03)
      }}>
        <TextField
          fullWidth
          placeholder="Поиск иконки..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="action" />
              </InputAdornment>
            ),
            endAdornment: searchQuery ? (
              <InputAdornment position="end">
                <IconButton 
                  size="small" 
                  onClick={() => setSearchQuery('')}
                  edge="end"
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            ) : null
          }}
          sx={{ mb: 2 }}
        />

        {!searchQuery && (
          <Tabs 
            value={activeTab} 
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            sx={{ 
              mb: 1,
              '& .MuiTab-root': {
                minWidth: 'auto',
                px: 2
              }
            }}
          >
            <Tab label="Все иконки" />
            {iconGroups.map((group, index) => (
              <Tab key={group.name} label={group.name} />
            ))}
          </Tabs>
        )}
      </Box>

      <DialogContent sx={{ 
        pt: 2, 
        height: '400px', 
        overflowY: 'auto'
      }}>
        {searchQuery && filteredIcons.length === 0 && (
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            justifyContent: 'center',
            height: '100%',
            color: 'text.secondary'
          }}>
            <SearchIcon sx={{ fontSize: 48, opacity: 0.3, mb: 2 }} />
            <Typography variant="body1">
              Иконки не найдены
            </Typography>
            <Typography variant="body2" color="text.disabled" sx={{ mt: 1 }}>
              Попробуйте изменить поисковый запрос
            </Typography>
          </Box>
        )}

        {displayedIcons.length > 0 && (
          <Grid container spacing={1}>
            {displayedIcons.map(({ name, component: Icon }) => {
              const isSelected = name === selectedIcon;
              const isHovered = name === hoveredIcon;
              
              return (
                <Grid item xs={4} sm={3} md={2} key={name}>
                  <Tooltip title={name.replace('Icon', '')}>
                    <Paper
                      elevation={0}
                      onClick={() => handleSelectIcon(name)}
                      onMouseEnter={() => setHoveredIcon(name)}
                      onMouseLeave={() => setHoveredIcon(null)}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        p: 2,
                        cursor: 'pointer',
                        height: '100px',
                        border: '1px solid',
                        borderColor: isSelected 
                          ? 'primary.main' 
                          : isHovered 
                            ? alpha(theme.palette.primary.main, 0.3) 
                            : 'divider',
                        bgcolor: isSelected 
                          ? alpha(theme.palette.primary.main, 0.1) 
                          : isHovered 
                            ? alpha(theme.palette.primary.main, 0.05) 
                            : 'background.paper',
                        borderRadius: 1,
                        transition: 'all 0.2s',
                        position: 'relative',
                        '&:hover': {
                          borderColor: 'primary.main',
                          bgcolor: alpha(theme.palette.primary.main, 0.05)
                        }
                      }}
                    >
                      {isSelected && (
                        <Box 
                          sx={{ 
                            position: 'absolute', 
                            top: 8, 
                            right: 8,
                            bgcolor: 'primary.main',
                            color: 'primary.contrastText',
                            borderRadius: '50%',
                            width: 20,
                            height: 20,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <CheckIcon sx={{ fontSize: 14 }} />
                        </Box>
                      )}
                      <Icon 
                        sx={{ 
                          fontSize: 36, 
                          color: isSelected 
                            ? 'primary.main' 
                            : isHovered 
                              ? 'primary.main' 
                              : 'text.primary',
                          mb: 1
                        }} 
                      />
                      <Typography 
                        variant="caption" 
                        color="text.secondary"
                        sx={{
                          textAlign: 'center',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          width: '100%',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        {name.replace('Icon', '')}
                      </Typography>
                    </Paper>
                  </Tooltip>
                </Grid>
              );
            })}
          </Grid>
        )}
      </DialogContent>

      <DialogActions sx={{ 
        borderTop: '1px solid',
        borderColor: 'divider',
        px: 3,
        py: 2
      }}>
        <Button onClick={onClose} color="inherit">
          Отмена
        </Button>
        <Button 
          onClick={() => handleSelectIcon(selectedIcon)} 
          variant="contained" 
          color="primary"
          disabled={!selectedIcon}
        >
          Выбрать
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default IconSelector;
