import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import useChannel from './useChannel';
import { getChannelSeo } from '../services/seoApi';
import { 
  getChannelUrl, 
  isCanonicalUrl, 
  getChannelApiEndpoint, 
  getChannelRequestParams 
} from '../services/urlService';
import { getCache, setCache, removeCache } from '../services/cacheService';
import { URL_TYPES } from '../constants/urlTypes';

// Константы для TTL кеша (время жизни)
const CACHE_TTL = {
  CHANNEL: 30 * 60 * 1000,         // 30 минут для основных данных канала
  RECOMMENDED: 15 * 60 * 1000,     // 15 минут для рекомендованных каналов
  RELATED: 20 * 60 * 1000,         // 20 минут для связанных каналов
  SEO: 60 * 60 * 1000              // 60 минут для SEO-метаданных
};

// Префиксы для ключей кеша
const CACHE_KEY = {
  CHANNEL: 'channel_data_',
  RECOMMENDED: 'channel_recommended_',
  RELATED: 'channel_related_',
  SEO: 'channel_seo_'
};

/**
 * Хук для получения данных канала и SEO-метаданных с поддержкой кеширования
 * @param {string} identifier - Идентификатор канала (username, identifier или id)
 * @param {string} channelPath - Полный URL-путь канала
 * @returns {Object} Данные канала, SEO-метаданные и состояние загрузки
 */
const useChannelWithSeo = (identifier, channelPath) => {
  const navigate = useNavigate();
  const viewIncrementedRef = useRef(false);
  
  // Состояния для данных
  const [channel, setChannel] = useState(null);
  const [recommendedChannels, setRecommendedChannels] = useState([]);
  const [relatedChannels, setRelatedChannels] = useState([]);
  const [seoData, setSeoData] = useState(null);
  
  // Состояния для загрузки
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingRecommended, setIsLoadingRecommended] = useState(true);
  const [isLoadingRelated, setIsLoadingRelated] = useState(true);
  const [isSeoLoading, setIsSeoLoading] = useState(true);
  
  // Состояния для ошибок
  const [error, setError] = useState(null);
  const [seoError, setSeoError] = useState(null);
  
  // Функция для увеличения просмотров канала
  const incrementViews = useCallback(async (channelData) => {
    if (!channelData || viewIncrementedRef.current) return;
    
    try {
      await axios.post(`/api/channels/${channelData._id}/view`);
      viewIncrementedRef.current = true;
    } catch (err) {
      console.error('[ERROR] useChannelWithSeo - Ошибка при увеличении просмотров:', err);
      // Даже если произошла ошибка, помечаем просмотр как учтенный,
      // чтобы избежать повторных запросов
      viewIncrementedRef.current = true;
    }
  }, []);

  // Функция для загрузки основных данных канала с поддержкой кеширования
  const fetchChannelData = useCallback(async () => {
    if (!identifier && !channelPath) return null;
    
    try {
      setIsLoading(true);
      setError(null);
      
      // Определяем параметры запроса
      let params;
      if (channelPath) {
        params = getChannelRequestParams(channelPath);
      } else if (identifier) {
        if (identifier.startsWith('@')) {
          params = { type: URL_TYPES.USERNAME, value: identifier.substring(1) };
        } else {
          params = { type: URL_TYPES.IDENTIFIER, value: identifier };
        }
      }
      
      // Получаем API endpoint
      const endpoint = getChannelApiEndpoint(params);
      if (!endpoint) {
        throw new Error('Не удалось определить API endpoint для запроса канала');
      }
      
      // Формируем ключ для кеша на основе endpoint
      const cacheKey = CACHE_KEY.CHANNEL + btoa(endpoint);
      
      // Проверяем наличие данных в кеше
      const cachedData = getCache(cacheKey);
      if (cachedData) {
        console.log('[DEBUG] useChannelWithSeo - Получены данные канала из кеша:', cachedData._id || 'неизвестный');
        
        // Используем кешированные данные
        setChannel(cachedData);
        setIsLoading(false);
        
        // Проверяем каноничность URL
        if (channelPath && !isCanonicalUrl(cachedData, channelPath)) {
          console.log('[INFO] useChannelWithSeo - Перенаправление на канонический URL');
          navigate(getChannelUrl(cachedData), { replace: true });
          return cachedData;
        }
        
        // Увеличиваем счетчик просмотров
        incrementViews(cachedData);
        
        // Параллельно проверяем актуальность данных
        setTimeout(() => {
          refreshChannelData(endpoint, cacheKey, cachedData);
        }, 100);
        
        return cachedData;
      }

      // Если данных в кеше нет, загружаем с сервера
      console.log('[DEBUG] useChannelWithSeo - Запрос канала:', { endpoint, params });
      
      // Используем Promise.race с таймаутом для ограничения времени ожидания основных данных
      const timeoutPromise = new Promise((_, reject) => 
        setTimeout(() => reject(new Error('Превышено время ожидания данных канала')), 5000)
      );
      
      const fetchPromise = axios.get(endpoint);
      
      try {
        const response = await Promise.race([fetchPromise, timeoutPromise]);
        const channelData = response.data;
        
        // Проверяем каноничность URL
        if (channelPath && !isCanonicalUrl(channelData, channelPath)) {
          console.log('[INFO] useChannelWithSeo - Перенаправление на канонический URL');
          navigate(getChannelUrl(channelData), { replace: true });
          return channelData;
        }
        
        // Сохраняем данные в кеш
        setCache(cacheKey, channelData, CACHE_TTL.CHANNEL);
        
        // Обновляем состояние
        setChannel(channelData);
        setIsLoading(false);
        
        // Увеличиваем счетчик просмотров
        incrementViews(channelData);
        
        return channelData;
      } catch (timeoutError) {
        console.warn('[WARN] useChannelWithSeo - Таймаут при загрузке данных канала:', timeoutError.message);
        
        // Продолжаем загрузку в фоне, но не блокируем интерфейс
        fetchPromise.then(response => {
          const channelData = response.data;
          
          // Сохраняем данные в кеш
          setCache(cacheKey, channelData, CACHE_TTL.CHANNEL);
          
          // Обновляем состояние
          setChannel(channelData);
          setIsLoading(false);
          
          // Увеличиваем счетчик просмотров
          incrementViews(channelData);
        }).catch(err => {
          console.error('[ERROR] useChannelWithSeo - Ошибка при фоновой загрузке данных канала:', err);
          setError(err.response?.data?.message || 'Ошибка при загрузке канала');
          setIsLoading(false);
        });
        
        throw new Error('Превышено время ожидания данных канала');
      }
    } catch (err) {
      console.error('[ERROR] useChannelWithSeo - Ошибка при загрузке канала:', err);
      setError(err.response?.data?.message || 'Ошибка при загрузке канала');
      setIsLoading(false);
      return null;
    }
  }, [identifier, channelPath, navigate, incrementViews]);
  
  // Функция для фонового обновления данных канала
  const refreshChannelData = useCallback(async (endpoint, cacheKey, currentData) => {
    try {
      const response = await axios.get(endpoint);
      const newData = response.data;
      
      // Проверяем, изменились ли данные
      const hasChanged = JSON.stringify(newData) !== JSON.stringify(currentData);
      
      if (hasChanged) {
        console.log('[DEBUG] useChannelWithSeo - Обновлены данные канала в кеше');
        
        // Обновляем кеш
        setCache(cacheKey, newData, CACHE_TTL.CHANNEL);
        
        // Обновляем состояние только если данные изменились
        setChannel(newData);
      }
    } catch (err) {
      console.error('[ERROR] useChannelWithSeo - Ошибка при обновлении данных канала:', err);
      // Не обновляем состояние ошибки, чтобы не прерывать пользовательский опыт
    }
  }, []);
  
  // Функция для загрузки рекомендованных каналов с поддержкой кеширования
  const fetchRecommendedChannels = useCallback(async (channelData) => {
    if (!channelData) return [];
    
    try {
      setIsLoadingRecommended(true);
      
      // Формируем ключ для кеша
      const cacheKey = CACHE_KEY.RECOMMENDED + 'global';
      
      // Проверяем наличие данных в кеше
      const cachedData = getCache(cacheKey);
      if (cachedData) {
        console.log('[DEBUG] useChannelWithSeo - Получены рекомендованные каналы из кеша');
        
        // Используем кешированные данные
        setRecommendedChannels(cachedData);
        setIsLoadingRecommended(false);
        
        // Параллельно проверяем актуальность данных
        refreshRecommendedChannels(cacheKey, cachedData);
        
        return cachedData;
      }
      
      // Если данных в кеше нет, загружаем с сервера
      const response = await axios.get('/api/channels/recommended');
      const recommendedData = response.data;
      
      // Сохраняем данные в кеш
      setCache(cacheKey, recommendedData, CACHE_TTL.RECOMMENDED);
      
      // Обновляем состояние
      setRecommendedChannels(recommendedData);
      setIsLoadingRecommended(false);
      
      return recommendedData;
    } catch (err) {
      console.error('[ERROR] useChannelWithSeo - Ошибка при загрузке рекомендаций:', err);
      setIsLoadingRecommended(false);
      return [];
    }
  }, []);
  
  // Функция для фонового обновления рекомендованных каналов
  const refreshRecommendedChannels = useCallback(async (cacheKey, currentData) => {
    try {
      const response = await axios.get('/api/channels/recommended');
      const newData = response.data;
      
      // Проверяем, изменились ли данные
      const hasChanged = JSON.stringify(newData) !== JSON.stringify(currentData);
      
      if (hasChanged) {
        console.log('[DEBUG] useChannelWithSeo - Обновлены рекомендованные каналы в кеше');
        
        // Обновляем кеш
        setCache(cacheKey, newData, CACHE_TTL.RECOMMENDED);
        
        // Обновляем состояние только если данные изменились
        setRecommendedChannels(newData);
      }
    } catch (err) {
      console.error('[ERROR] useChannelWithSeo - Ошибка при обновлении рекомендованных каналов:', err);
      // Не обновляем состояние ошибки, чтобы не прерывать пользовательский опыт
    }
  }, []);
  
  // Функция для загрузки связанных каналов с поддержкой кеширования
  const fetchRelatedChannels = useCallback(async (channelData) => {
    if (!channelData || !channelData.categories || channelData.categories.length === 0) {
      setIsLoadingRelated(false);
      return [];
    }
    
    try {
      setIsLoadingRelated(true);
      
      // Формируем ключ для кеша на основе ID канала и категории
      const category = channelData.categories[0];
      const cacheKey = CACHE_KEY.RELATED + channelData._id + '_' + category;
      
      // Проверяем наличие данных в кеше
      const cachedData = getCache(cacheKey);
      if (cachedData) {
        console.log('[DEBUG] useChannelWithSeo - Получены связанные каналы из кеша для канала:', channelData._id);
        
        // Используем кешированные данные
        setRelatedChannels(cachedData);
        setIsLoadingRelated(false);
        
        // Параллельно проверяем актуальность данных
        refreshRelatedChannels(channelData, cacheKey, cachedData);
        
        return cachedData;
      }
      
      // Если данных в кеше нет, загружаем с сервера
      const response = await axios.get('/api/channels/recommended', {
        params: { 
          channelId: channelData._id,
          category: category
        }
      });
      const relatedData = response.data;
      
      // Сохраняем данные в кеш
      setCache(cacheKey, relatedData, CACHE_TTL.RELATED);
      
      // Обновляем состояние
      setRelatedChannels(relatedData);
      setIsLoadingRelated(false);
      
      return relatedData;
    } catch (err) {
      console.error('[ERROR] useChannelWithSeo - Ошибка при загрузке связанных каналов:', err);
      setIsLoadingRelated(false);
      return [];
    }
  }, []);
  
  // Функция для фонового обновления связанных каналов
  const refreshRelatedChannels = useCallback(async (channelData, cacheKey, currentData) => {
    try {
      const category = channelData.categories[0];
      const response = await axios.get('/api/channels/recommended', {
        params: { 
          channelId: channelData._id,
          category: category
        }
      });
      const newData = response.data;
      
      // Проверяем, изменились ли данные
      const hasChanged = JSON.stringify(newData) !== JSON.stringify(currentData);
      
      if (hasChanged) {
        console.log('[DEBUG] useChannelWithSeo - Обновлены связанные каналы в кеше');
        
        // Обновляем кеш
        setCache(cacheKey, newData, CACHE_TTL.RELATED);
        
        // Обновляем состояние только если данные изменились
        setRelatedChannels(newData);
      }
    } catch (err) {
      console.error('[ERROR] useChannelWithSeo - Ошибка при обновлении связанных каналов:', err);
      // Не обновляем состояние ошибки, чтобы не прерывать пользовательский опыт
    }
  }, []);
  
  // Загрузка SEO-метаданных с поддержкой кеширования
  const fetchChannelSeo = useCallback(async () => {
    if (!channel) return null;
    
    try {
      setIsSeoLoading(true);
      
      // Формируем ключ для кэша
      const cacheKey = CACHE_KEY.SEO + channel._id;
      
      // Проверяем наличие данных в кэше
      const cachedData = getCache(cacheKey);
      if (cachedData) {
        console.log('[DEBUG] useChannelWithSeo - Получены SEO данные из кэша для канала:', channel._id);
        setSeoData(cachedData);
        setIsSeoLoading(false);
        return cachedData;
      }
      
      // Определяем slug для запроса
      const slug = channel.username ? `@${channel.username}` : channel.slug;
      
      // Используем Promise.race с таймаутом для ограничения времени ожидания
      const timeoutPromise = new Promise((_, reject) => 
        setTimeout(() => reject(new Error('Превышено время ожидания SEO-данных')), 3000)
      );
      
      const fetchPromise = getChannelSeo(slug);
      
      try {
        // Запрашиваем данные с сервера с ограничением по времени
        const data = await Promise.race([fetchPromise, timeoutPromise]);
        
        // Проверяем и исправляем canonicalUrl, если он некорректный
        if (data && data.canonicalUrl && data.canonicalUrl.includes('/channel/null')) {
          console.warn('[DEBUG] useChannelWithSeo - Получен некорректный canonicalUrl:', data.canonicalUrl);
          // Используем текущий URL вместо некорректного
          data.canonicalUrl = window.location.origin + getChannelUrl(channel);
        }
        
        // Сохраняем данные в кеш
        if (data) {
          setCache(cacheKey, data, CACHE_TTL.SEO);
        }
        
        setSeoData(data);
        return data;
      } catch (timeoutError) {
        console.warn('[WARN] useChannelWithSeo - Таймаут при загрузке SEO-данных:', timeoutError.message);
        // Продолжаем загрузку в фоне, но не блокируем интерфейс
        fetchPromise.then(data => {
          if (data) {
            setSeoData(data);
            setCache(cacheKey, data, CACHE_TTL.SEO);
          }
        }).catch(err => {
          console.error('[ERROR] useChannelWithSeo - Ошибка при фоновой загрузке SEO-данных:', err);
        }).finally(() => {
          setIsSeoLoading(false);
        });
        
        // Возвращаем базовые SEO-данные для быстрого отображения
        const basicSeoData = {
          title: `${channel.name} - TeleHunt`,
          description: `Подробная информация о Telegram канале ${channel.name} на TeleHunt - каталоге Telegram каналов`,
          canonicalUrl: window.location.origin + getChannelUrl(channel)
        };
        
        setSeoData(basicSeoData);
        return basicSeoData;
      }
    } catch (error) {
      console.error('[ERROR] useChannelWithSeo - Ошибка при загрузке SEO данных:', error);
      setSeoError(error);
      
      // Возвращаем базовые SEO-данные в случае ошибки
      if (channel) {
        const fallbackSeoData = {
          title: `${channel.name} - TeleHunt`,
          description: `Подробная информация о Telegram канале ${channel.name} на TeleHunt - каталоге Telegram каналов`,
          canonicalUrl: window.location.origin + getChannelUrl(channel)
        };
        setSeoData(fallbackSeoData);
        return fallbackSeoData;
      }
      
      return null;
    } finally {
      setIsSeoLoading(false);
    }
  }, [channel]);
  
  // Эффект для обновления SEO-метаданных при изменении канала
  useEffect(() => {
    if (channel) {
      fetchChannelSeo();
    }
  }, [channel]);
  
  // Основной эффект для загрузки данных канала
  useEffect(() => {
    let isActive = true;
    viewIncrementedRef.current = false;
    
    const loadData = async () => {
      try {
        // Загружаем основные данные канала
        const channelData = await fetchChannelData();
        
        if (!isActive || !channelData) return;
        
        // Параллельно загружаем рекомендованные и связанные каналы
        fetchRecommendedChannels(channelData);
        fetchRelatedChannels(channelData);
      } catch (err) {
        if (!isActive) return;
        console.error('[ERROR] useChannelWithSeo - Ошибка при загрузке данных:', err);
      }
    };
    
    loadData();
    
    return () => {
      isActive = false;
    };
  }, [identifier, channelPath, fetchChannelData, fetchRecommendedChannels, fetchRelatedChannels]);
  
  // Проверка загрузки всех данных
  const isAllDataLoaded = !isLoading && !isSeoLoading;
  
  // Функция для принудительного обновления всех данных
  const refreshAllData = useCallback(async () => {
    if (!channel) return;
    
    // Удаляем данные из кеша
    removeCache(CACHE_KEY.CHANNEL + channel._id);
    removeCache(CACHE_KEY.RECOMMENDED + 'global');
    removeCache(CACHE_KEY.RELATED + channel._id + '_' + (channel.categories?.[0] || ''));
    removeCache(CACHE_KEY.SEO + channel._id);
    
    // Перезагружаем данные
    await fetchChannelData();
    await fetchRecommendedChannels(channel);
    await fetchRelatedChannels(channel);
    await fetchChannelSeo();
  }, [channel, fetchChannelData, fetchRecommendedChannels, fetchRelatedChannels, fetchChannelSeo]);
  
  return {
    // Данные канала
    channel,
    recommendedChannels,
    relatedChannels,
    
    // SEO-метаданные
    seoData,
    
    // Состояние загрузки
    isLoading,
    isLoadingRecommended,
    isLoadingRelated,
    isSeoLoading,
    isAllDataLoaded,
    
    // Ошибки
    error,
    seoError,
    
    // Методы
    fetchChannelSeo,
    refreshAllData
  };
};

export default useChannelWithSeo;
