import React, { createContext, useContext, useState, useEffect } from 'react';
import api from '../services/api';
import { refreshCsrfToken } from '../services/csrfService';

const AuthContext = createContext(null);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem('user');
    return savedUser ? JSON.parse(savedUser) : null;
  });
  const [token, setToken] = useState(() => {
    return localStorage.getItem('token') || null;
  });

  // Инициализация CSRF-токена при загрузке
  useEffect(() => {
    refreshCsrfToken().catch((error) => {
      console.error('Ошибка при обновлении CSRF-токена:', error);
    });
  }, []);

  const register = async (username, password) => {
    try {
      console.log('Registering user:', { username });
      const response = await api.post('/api/auth/register', {
        username,
        password
      });
      console.log('Registration successful:', response.data);
      return response.data;
    } catch (error) {
      console.error('Registration failed:', error.response?.data || error.message);
      throw error;
    }
  };

  const login = async (username, password) => {
    try {
      console.log('Logging in user:', { username });
      const response = await api.post('/api/auth/login', { username, password });
      
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
        
        // Создаем объект пользователя из данных ответа
        const userData = {
          username: response.data.username,
          isAdmin: response.data.isAdmin
        };
        
        localStorage.setItem('user', JSON.stringify(userData));
        setToken(response.data.token);
        setUser(userData);
        
        // Обновляем CSRF-токен после успешной авторизации
        await refreshCsrfToken();
        
        return { 
          success: true, 
          user: userData, 
          token: response.data.token 
        };
      }
      
      return { success: false, error: 'Неверный логин или пароль' };
    } catch (error) {
      console.error('Ошибка при входе:', error.response?.data?.message || error.message);
      return { 
        success: false, 
        error: error.response?.data?.message || 'Ошибка при входе. Пожалуйста, попробуйте позже.' 
      };
    }
  };

  const logout = async () => {
    try {
      // Отправляем запрос на выход
      await api.post('/api/auth/logout');
    } catch (error) {
      console.error('Ошибка при выходе из системы:', error.response?.data?.message || error.message);
    } finally {
      // Очищаем данные аутентификации
      setUser(null);
      setToken(null);
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      
      // Обновляем CSRF-токен после выхода
      refreshCsrfToken().catch(error => {
        console.warn('Ошибка при обновлении CSRF-токена после выхода:', error);
      });
    }
  };

  return (
    <AuthContext.Provider value={{
      user,
      token,
      register,
      login,
      logout,
      isAuthenticated: !!token
    }}>
      {children}
    </AuthContext.Provider>
  );
};
