import React from 'react';
import { 
  Box, 
  Typography, 
  Grid,
  alpha
} from '@mui/material';
import { formatNumber } from '../../utils/formatters';

/**
 * Компонент статистики для страницы деталей канала
 * 
 * @param {Object} props - Свойства компонента
 * @param {Object} props.channel - Данные канала
 * @returns {JSX.Element} Компонент статистики канала
 */
const DetailStats = ({ channel }) => {
  return (
    <Grid container spacing={2} sx={{ mb: 3 }}>
      <Grid item xs={6}>
        <Box sx={{ 
          p: 2,
          borderRadius: 2,
          bgcolor: theme => alpha(theme.palette.primary.main, 0.04),
          border: theme => `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
          transition: 'all 0.3s ease-in-out',
          '&:hover': {
            transform: 'translateY(-4px)',
            bgcolor: theme => alpha(theme.palette.primary.main, 0.06),
            boxShadow: theme => `0 4px 12px ${alpha(theme.palette.primary.main, 0.1)}`
          }
        }}>
          <Typography variant="subtitle2" color="text.secondary" sx={{ 
            mb: 0.5,
            fontWeight: 500,
            fontSize: { xs: '0.875rem', sm: '0.875rem' },
            WebkitFontSmoothing: 'antialiased',
            MozOsxFontSmoothing: 'grayscale',
            textRendering: 'optimizeLegibility'
          }}>
            Подписчики
          </Typography>
          <Typography variant="h5" sx={{ 
            fontWeight: 700,
            fontSize: { xs: '1.25rem', sm: '1.5rem' },
            color: 'primary.main',
            WebkitFontSmoothing: 'antialiased',
            MozOsxFontSmoothing: 'grayscale',
            textRendering: 'optimizeLegibility'
          }}>
            {formatNumber(channel.subscribers)}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box sx={{ 
          p: 2,
          borderRadius: 2,
          bgcolor: theme => alpha(theme.palette.primary.main, 0.04),
          border: theme => `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
          transition: 'all 0.3s ease-in-out',
          '&:hover': {
            transform: 'translateY(-4px)',
            bgcolor: theme => alpha(theme.palette.primary.main, 0.06),
            boxShadow: theme => `0 4px 12px ${alpha(theme.palette.primary.main, 0.1)}`
          }
        }}>
          <Typography variant="subtitle2" color="text.secondary" sx={{ 
            mb: 0.5,
            fontWeight: 500,
            fontSize: { xs: '0.875rem', sm: '0.875rem' },
            WebkitFontSmoothing: 'antialiased',
            MozOsxFontSmoothing: 'grayscale',
            textRendering: 'optimizeLegibility'
          }}>
            Просмотры
          </Typography>
          <Typography variant="h5" sx={{ 
            fontWeight: 700,
            fontSize: { xs: '1.25rem', sm: '1.5rem' },
            color: 'primary.main',
            WebkitFontSmoothing: 'antialiased',
            MozOsxFontSmoothing: 'grayscale',
            textRendering: 'optimizeLegibility'
          }}>
            {formatNumber(channel.views)}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default DetailStats;
