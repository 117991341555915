import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@mui/material';

/**
 * Компонент модального окна для подтверждения удаления
 * @param {Object} props - Свойства компонента
 * @param {boolean} props.show - Флаг отображения модального окна
 * @param {Function} props.onHide - Функция закрытия модального окна
 * @param {Function} props.onConfirm - Функция подтверждения удаления
 * @param {string} props.title - Заголовок модального окна
 * @param {string} props.message - Сообщение в модальном окне
 * @returns {JSX.Element}
 */
const DeleteConfirmationModal = ({ show, onHide, onConfirm, title, message }) => {
  return (
    <Dialog
      open={show}
      onClose={onHide}
      aria-labelledby="delete-confirmation-dialog-title"
      aria-describedby="delete-confirmation-dialog-description"
    >
      <DialogTitle id="delete-confirmation-dialog-title">
        {title || 'Подтверждение удаления'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="delete-confirmation-dialog-description">
          {message || 'Вы уверены, что хотите удалить этот элемент?'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onHide} color="primary">
          Отмена
        </Button>
        <Button onClick={onConfirm} color="error" variant="contained">
          Удалить
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationModal;
