import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../services/api';

export const useAdminAuth = () => {
  const navigate = useNavigate();
  const [isChecking, setIsChecking] = useState(true);

  useEffect(() => {
    const checkAdminAuth = async () => {
      try {
        const token = localStorage.getItem('token');
        
        if (!token) {
          navigate('/login');
          return;
        }

        // Используем api-клиент для проверки прав администратора
        const response = await api.get('/api/stats/admin');

        // Если запрос успешен, устанавливаем флаг админа
        if (response.status === 200) {
          localStorage.setItem('isAdminAuthenticated', 'true');
        } else {
          // Если не админ или токен истек - очищаем и редиректим
          localStorage.removeItem('token');
          localStorage.removeItem('isAdminAuthenticated');
          localStorage.removeItem('user');
          navigate('/login');
        }
      } catch (error) {
        console.error('Ошибка проверки прав администратора:', error);
        
        // Если ошибка авторизации, перенаправляем на страницу входа
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('isAdminAuthenticated');
          localStorage.removeItem('user');
          navigate('/login');
        }
      } finally {
        setIsChecking(false);
      }
    };

    checkAdminAuth();
  }, [navigate]);

  return { isChecking };
};
