import React from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  Grid, 
  Link, 
  Button,
  Stack,
  Paper
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import logo from '../assets/images/logo.svg';

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        mt: 'auto',
        pt: 8,
        pb: 6,
        backgroundColor: '#1e293b',
        position: 'relative',
        overflow: 'hidden',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '4px',
          background: 'linear-gradient(90deg, #0088cc, #00c6ff)',
        }
      }}
    >
      <Container maxWidth="lg">
        {/* Логотип */}
        <Box sx={{ textAlign: 'center', mb: 6, position: 'relative' }}>
          <Box 
            sx={{ 
              display: 'inline-flex',
              alignItems: 'center',
              gap: 1,
              mb: 3
            }}
          >
            <Box
              sx={{ 
                width: 48,
                height: 48,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <img 
                src={logo} 
                alt="TeleHunt Logo" 
                style={{ 
                  width: '100%', 
                  height: '100%', 
                  objectFit: 'contain' 
                }} 
              />
            </Box>
            <Typography
              variant="h3"
              sx={{
                fontWeight: 800,
                letterSpacing: '0.5px',
                textTransform: 'uppercase',
                background: 'linear-gradient(135deg, #0088cc 0%, #33a1d6 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                position: 'relative',
                whiteSpace: 'nowrap',
                display: 'block',
                fontSize: { xs: '1.2rem', sm: '1.5rem' },
                marginLeft: 0.5
              }}
            >
              TeleHunt
            </Typography>
          </Box>
          <Typography
            variant="h6"
            sx={{
              color: '#94a3b8',
              maxWidth: '600px',
              mx: 'auto',
              fontWeight: 500
            }}
          >
            Исследуйте бескрайний мир Telegram
          </Typography>
        </Box>

        {/* Основной контент футера */}
        <Grid container spacing={6} sx={{ mb: 8 }}>
          {/* Полезные ссылки */}
          <Grid item xs={12} md={4}>
            <Paper
              elevation={0}
              sx={{
                p: 3,
                backgroundColor: 'rgba(255, 255, 255, 0.03)',
                borderRadius: 2,
                border: '1px solid rgba(255, 255, 255, 0.1)',
                height: '100%',
                transition: 'transform 0.2s',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  backgroundColor: 'rgba(255, 255, 255, 0.05)'
                }
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  color: '#fff',
                  fontWeight: 600,
                  mb: 3,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1
                }}
              >
                <SearchIcon sx={{ color: '#0088cc' }} />
                Полезные ссылки
              </Typography>
              <Stack spacing={2}>
                {[
                  { text: 'Контакты', path: '/contacts' },
                  { text: 'Политика конфиденциальности', path: '/privacy' },
                  { text: 'Правила использования', path: '/terms' },
                  { text: 'Запрос на удаление', path: '/removal-request' }
                ].map((item, index) => (
                  <Link
                    key={index}
                    component={RouterLink}
                    to={item.path}
                    sx={{
                      color: '#94a3b8',
                      textDecoration: 'none',
                      fontSize: '0.95rem',
                      transition: 'all 0.2s',
                      display: 'block',
                      p: 1,
                      borderRadius: 1,
                      '&:hover': {
                        color: '#fff',
                        backgroundColor: 'rgba(0, 136, 204, 0.1)',
                        paddingLeft: '16px'
                      }
                    }}
                  >
                    {item.text}
                  </Link>
                ))}
              </Stack>
            </Paper>
          </Grid>

          {/* Меню */}
          <Grid item xs={12} md={4}>
            <Paper
              elevation={0}
              sx={{
                p: 3,
                backgroundColor: 'rgba(255, 255, 255, 0.03)',
                borderRadius: 2,
                border: '1px solid rgba(255, 255, 255, 0.1)',
                height: '100%',
                transition: 'transform 0.2s',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  backgroundColor: 'rgba(255, 255, 255, 0.05)'
                }
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  color: '#fff',
                  fontWeight: 600,
                  mb: 3,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1
                }}
              >
                <SearchIcon sx={{ color: '#0088cc' }} />
                Меню
              </Typography>
              <Stack spacing={2}>
                {[
                  { text: 'Категории', path: '/categories' },
                  { text: 'Блог', path: '/blog' },
                  { text: 'FAQ', path: '/faq' }
                ].map((item, index) => (
                  <Link
                    key={index}
                    component={RouterLink}
                    to={item.path}
                    sx={{
                      color: '#94a3b8',
                      textDecoration: 'none',
                      fontSize: '0.95rem',
                      transition: 'all 0.2s',
                      display: 'block',
                      p: 1,
                      borderRadius: 1,
                      '&:hover': {
                        color: '#fff',
                        backgroundColor: 'rgba(0, 136, 204, 0.1)',
                        paddingLeft: '16px'
                      }
                    }}
                  >
                    {item.text}
                  </Link>
                ))}
              </Stack>
            </Paper>
          </Grid>

          {/* Добавить в каталог */}
          <Grid item xs={12} md={4}>
            <Paper
              elevation={0}
              sx={{
                p: 3,
                backgroundColor: 'rgba(255, 255, 255, 0.03)',
                borderRadius: 2,
                border: '1px solid rgba(255, 255, 255, 0.1)',
                height: '100%',
                transition: 'transform 0.2s',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  backgroundColor: 'rgba(255, 255, 255, 0.05)'
                }
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  color: '#fff',
                  fontWeight: 600,
                  mb: 3,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1
                }}
              >
                <AddIcon sx={{ color: '#0088cc' }} />
                Добавить канал
              </Typography>
              <Typography
                sx={{
                  color: '#94a3b8',
                  mb: 3,
                  lineHeight: 1.6
                }}
              >
                Поделитесь своим каналом с тысячами пользователей. Это бесплатно!
              </Typography>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                component={RouterLink}
                to="/add-channel"
                sx={{
                  backgroundColor: '#0088cc',
                  color: 'white',
                  textTransform: 'none',
                  borderRadius: 1,
                  py: 1.5,
                  px: 3,
                  fontWeight: 600,
                  transition: 'all 0.3s',
                  background: 'linear-gradient(45deg, #0088cc, #00a6e6)',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 20px rgba(0, 136, 204, 0.4)'
                  }
                }}
              >
                Добавить в каталог
              </Button>
            </Paper>
          </Grid>
        </Grid>

        {/* Описание */}
        <Box 
          sx={{ 
            textAlign: 'center',
            position: 'relative',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: '-20px',
              left: '50%',
              transform: 'translateX(-50%)',
              width: '60px',
              height: '2px',
              background: 'linear-gradient(90deg, transparent, #0088cc, transparent)'
            }
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: '#64748b',
              mb: 2,
              fontSize: '0.9rem',
              lineHeight: 1.6
            }}
          >
            Обратите внимание: данный сайт не имеет никакого отношения к компании Telegram Messenger LLP 
            и другим любым торговым маркам, знакам и названиям товаров, служб и организациях, 
            а только расширяет функционал поиска оригинального приложения Telegram.
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: '#64748b',
              fontSize: '0.9rem'
            }}
          >
            {new Date().getFullYear()} TeleHunt. Все права защищены.
          </Typography>
        </Box>
      </Container>

      <style>
        {`
          @keyframes pulse {
            0% {
              transform: scale(1);
            }
            50% {
              transform: scale(1.05);
            }
            100% {
              transform: scale(1);
            }
          }
        `}
      </style>
    </Box>
  );
};

export default Footer;
