import React, { useRef, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  IconButton,
} from '@mui/material';
import {
  CloudUpload as CloudUploadIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';

const ImageUpload = ({ onImageUpload, onImageDelete, currentImage }) => {
  const fileInputRef = useRef(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState('');

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Проверяем размер файла (не более 2MB)
    if (file.size > 2 * 1024 * 1024) {
      setError('Размер файла не должен превышать 2MB');
      return;
    }

    // Проверяем тип файла
    if (!file.type.startsWith('image/')) {
      setError('Пожалуйста, загрузите изображение');
      return;
    }

    try {
      setUploading(true);
      setError('');

      const formData = new FormData();
      formData.append('image', file);

      const response = await fetch('/api/articles/upload-image', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to upload image');
      }

      const data = await response.json();
      if (data && data.filename) {
        onImageUpload(data.filename);
      } else {
        throw new Error('Ошибка загрузки изображения');
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      setError('Ошибка загрузки изображения');
    } finally {
      setUploading(false);
    }
  };

  const handleDeleteClick = () => {
    if (onImageDelete) {
      onImageDelete();
    }
  };

  return (
    <Box sx={{ width: '100%', mt: 2 }}>
      <input
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={handleFileChange}
      />
      
      <Box sx={{ 
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
        padding: 3,
        border: '2px dashed',
        borderColor: 'grey.300',
        borderRadius: 1,
        backgroundColor: 'grey.50',
        position: 'relative'
      }}>
        {currentImage ? (
          <Box sx={{ position: 'relative' }}>
            <img
              src={`/api/articles/uploads/preview/${currentImage.replace(/\.[^/.]+$/, '')}-preview.webp`}
              alt="Preview"
              style={{
                maxWidth: '100%',
                maxHeight: '200px',
                objectFit: 'contain'
              }}
            />
            <IconButton
              onClick={handleDeleteClick}
              sx={{
                position: 'absolute',
                top: -12,
                right: -12,
                bgcolor: 'background.paper',
                '&:hover': {
                  bgcolor: 'error.light',
                  color: 'white'
                }
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        ) : (
          <>
            <Button
              variant="outlined"
              startIcon={<CloudUploadIcon />}
              onClick={() => fileInputRef.current?.click()}
              disabled={uploading}
            >
              {uploading ? 'Загрузка...' : 'Загрузить изображение'}
            </Button>
            {error && (
              <Typography color="error" variant="caption">
                {error}
              </Typography>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default ImageUpload;
