/**
 * Константы для работы с URL каналов
 * Используются для унификации терминологии во всем проекте
 */

// Типы идентификаторов каналов
export const URL_TYPES = {
  USERNAME: 'username',    // Для публичных каналов с @username
  IDENTIFIER: 'identifier', // Для приватных каналов
  ID: 'id'                 // Для прямого доступа по ID
};

// Регулярные выражения для проверки URL и ссылок
export const URL_PATTERNS = {
  USERNAME: /^@([a-zA-Z0-9_]+)$/,
  TELEGRAM_LINK: /^https:\/\/t\.me\/([^/]+)$/,
  PRIVATE_LINK: /^https:\/\/t\.me\/(joinchat\/|[+])([a-zA-Z0-9_-]+)$/
};

// Префиксы URL для разных типов каналов
export const URL_PREFIXES = {
  CHANNEL: '/channel/',
  USERNAME: '/channel/@',
  ID: '/channel/id/'
};
