import React, { useState, useCallback, useEffect } from 'react';
import {
  Paper,
  Typography,
  Box,
  Chip,
  alpha,
  CircularProgress,
  Button
} from '@mui/material';
import { styled } from '@mui/material/styles';
import TelegramIcon from '@mui/icons-material/Telegram';
import VerifiedIcon from '@mui/icons-material/Verified';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { formatNumber } from '../utils/formatters';
import { getCategoryUrl } from '../services/categoryService';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { getAvatarUrl, checkImageUrl, getOptimalAvatarSize, migrateLegacyAvatarPath } from '../utils/mediaUtils';

// Кэш для URL аватаров
const avatarCache = new Map();

const StyledLazyAvatar = styled(LazyLoadImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  display: block;
  
  &.placeholder {
    filter: blur(10px);
    transform: scale(1.1);
  }
  
  &.loaded {
    filter: blur(0);
    transform: scale(1);
  }
`;

const AvatarWrapper = styled(Box)(({ theme, isPremium }) => ({
  width: 64,
  height: 64,
  borderRadius: 8,
  overflow: 'hidden',
  position: 'relative',
  boxShadow: isPremium 
    ? `0 0 0 2px ${theme.palette.warning.main}, 0 4px 8px ${alpha(theme.palette.warning.main, 0.5)}` 
    : 'none',
  '&::before': isPremium ? {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: 8,
    zIndex: 1,
    pointerEvents: 'none',
  } : {},
}));

const PlaceholderBox = styled(Box)(({ theme, isPremium }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '1.5rem',
  fontWeight: 'bold',
  color: isPremium ? theme.palette.warning.main : theme.palette.primary.main,
}));

const TelegramIconStyled = styled(TelegramIcon)({
  animation: 'pulse 2s infinite',
  '@keyframes pulse': {
    '0%': { transform: 'scale(1)' },
    '50%': { transform: 'scale(1.1)' },
    '100%': { transform: 'scale(1)' },
  },
});

const ModernChannelCard = ({ channel, currentCategory, onClick }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [isValidating, setIsValidating] = useState(true);

  // Используем новую утилиту для получения оптимального размера аватара
  const avatarUrl = channel.avatar 
    ? getAvatarUrl(migrateLegacyAvatarPath(channel.avatar), getOptimalAvatarSize('card')) 
    : null;

  // Функция для проверки URL изображения
  const validateImageUrl = useCallback(async (url) => {
    if (!url) return false;
    
    // Проверяем, есть ли URL в кэше
    if (avatarCache.has(url)) {
      return avatarCache.get(url);
    }
    
    try {
      const response = await fetch(url, { method: 'HEAD' });
      const isValid = response.ok;
      
      // Сохраняем результат в кэше
      avatarCache.set(url, isValid);
      
      return isValid;
    } catch (error) {
      console.error('Ошибка при проверке URL изображения:', error);
      avatarCache.set(url, false);
      return false;
    }
  }, []);

  useEffect(() => {
    let isMounted = true;
    setIsValidating(true);
    
    const validateAndLoadImage = async () => {
      if (!avatarUrl) {
        setImageError(true);
        setIsValidating(false);
        return;
      }

      const isValid = await validateImageUrl(avatarUrl);
      
      if (isMounted) {
        setImageError(!isValid);
        setIsValidating(false);
      }
    };

    validateAndLoadImage();

    return () => {
      isMounted = false;
    };
  }, [avatarUrl, validateImageUrl]);

  // Определяем, показывать ли премиум-функции
  const showPremiumFeatures = currentCategory !== 'Все';
  // Вычисляем, является ли канал премиальным в текущем контексте
  const isChannelPremium = channel.isPremium && showPremiumFeatures;

  return (
    <Paper
      elevation={0}
      onClick={onClick}
      sx={{
        p: 2.5,
        height: '100%',
        border: '1px solid',
        borderColor: 'divider',
        transition: 'all 0.2s',
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        '&:hover': {
          borderColor: isChannelPremium ? 'warning.main' : 'primary.main',
          transform: 'translateY(-2px)',
          boxShadow: (theme) => isChannelPremium 
            ? `0 4px 20px ${alpha(theme.palette.warning.main, 0.15)}`
            : `0 4px 20px ${alpha(theme.palette.primary.main, 0.15)}`,
        },
      }}
    >
      <Box sx={{ display: 'flex', mb: 2 }}>
        {/* Аватар с оптимизированной загрузкой */}
        <AvatarWrapper isPremium={isChannelPremium}>
          {isValidating ? (
            <PlaceholderBox isPremium={isChannelPremium}>
              <CircularProgress size={24} color={isChannelPremium ? "warning" : "primary"} />
            </PlaceholderBox>
          ) : imageError ? (
            <PlaceholderBox isPremium={isChannelPremium}>
              {channel.name[0]}
            </PlaceholderBox>
          ) : (
            <StyledLazyAvatar
              src={avatarUrl}
              alt={channel.name}
              effect="blur"
              className={isLoaded ? 'loaded' : 'placeholder'}
              afterLoad={() => setIsLoaded(true)}
              onError={() => setImageError(true)}
              wrapperProps={{
                style: {
                  width: '100%',
                  height: '100%',
                  display: 'block'
                }
              }}
            />
          )}
        </AvatarWrapper>

        <Box sx={{ flex: 1 }}>
          {/* Название канала */}
          <Typography
            variant="h6"
            component="h3"
            sx={{
              fontWeight: 700,
              mb: 0.5,
              lineHeight: 1.2,
              color: 'text.primary',
            }}
          >
            {channel.name}
            {channel.isVerified && showPremiumFeatures && (
              <VerifiedIcon
                sx={{
                  ml: 0.5,
                  fontSize: '1rem',
                  color: isChannelPremium
                    ? 'warning.main'
                    : 'primary.main',
                  verticalAlign: 'middle',
                }}
              />
            )}
          </Typography>

          {/* Username или категория */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            {currentCategory && currentCategory !== 'Все' ? (
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 500,
                  color: isChannelPremium
                    ? 'warning.main'
                    : 'primary.main',
                }}
              >
                {channel.isPrivate ? 'Закрытый канал' : `@${channel.username}`}
              </Typography>
            ) : (
              <Chip
                icon={<LocalOfferIcon sx={{ fontSize: '0.8rem' }} />}
                label={
                  channel.categories &&
                  Array.isArray(channel.categories) &&
                  channel.categories.length > 0
                    ? channel.categories[0]
                    : 'Общее'
                }
                size="small"
                component={RouterLink}
                to={getCategoryUrl(channel.categories && Array.isArray(channel.categories) && channel.categories.length > 0 
                  ? channel.categories[0] 
                  : 'Общее')}
                clickable
                sx={{
                  height: 22,
                  '& .MuiChip-label': {
                    px: 1,
                    fontSize: '0.7rem',
                    lineHeight: 1.2,
                  },
                  '& .MuiChip-icon': {
                    fontSize: '0.8rem',
                    ml: 0.5,
                  },
                  bgcolor: theme => alpha(
                    isChannelPremium
                      ? theme.palette.warning.main 
                      : theme.palette.primary.main,
                    0.1
                  ),
                  color: isChannelPremium
                    ? 'warning.main'
                    : 'primary.main',
                  border: '1px solid',
                  borderColor: theme => alpha(
                    isChannelPremium
                      ? theme.palette.warning.main 
                      : theme.palette.primary.main,
                    0.2
                  ),
                }}
              />
            )}
          </Box>
        </Box>
      </Box>

      {/* Описание с оптимизированным рендерингом */}
      <Typography
        variant="body2"
        color="text.secondary"
        sx={{
          mb: 2,
          flexGrow: 1,
          display: '-webkit-box',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          position: 'relative',
          lineHeight: '1.5em',
          maxHeight: '3em', // Точно 2 строки
          wordBreak: 'break-word',
          '&::after': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            right: 0,
            width: '30%',
            height: '1.5em',
            background: theme => 
              isChannelPremium
                ? 'none'
                : `linear-gradient(to right, ${alpha(theme.palette.background.paper, 0)}, ${theme.palette.background.paper} 90%)`,
          }
        }}
      >
        {channel.description}
      </Typography>

      {/* Нижняя часть карточки */}
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {/* Количество подписчиков */}
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
          }}
        >
          <PeopleOutlineIcon sx={{ fontSize: '1rem' }} />
          {formatNumber(channel.subscribers)}
        </Typography>

        {/* Кнопка */}
        <Button
          variant="contained"
          size="small"
          className="channel-button"
          startIcon={<TelegramIconStyled />}
          sx={{
            textTransform: 'none',
            borderRadius: '8px',
            px: 2,
            transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
            bgcolor: isChannelPremium
              ? 'warning.main'
              : 'primary.main',
            background: isChannelPremium
              ? theme => `linear-gradient(45deg, ${theme.palette.warning.main}, ${theme.palette.warning.light})`
              : theme => `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
            boxShadow: theme => `0 4px 12px ${alpha(theme.palette.primary.main, 0.2)}`,
            '&:hover': {
              transform: 'translateY(-2px)',
              boxShadow: theme => `0 6px 16px ${alpha(theme.palette.primary.main, 0.3)}`,
              bgcolor: isChannelPremium
                ? 'warning.dark'
                : 'primary.dark',
              background: isChannelPremium
                ? theme => `linear-gradient(45deg, ${theme.palette.warning.light}, ${theme.palette.warning.main})`
                : theme => `linear-gradient(45deg, ${theme.palette.primary.light}, ${theme.palette.primary.main})`,
            },
          }}
          onClick={(e) => {
            e.stopPropagation();
            const telegramUrl = channel.isPrivate
              ? channel.link // Используем оригинальную ссылку из поля link
              : `https://t.me/${channel.username}`;
            window.open(telegramUrl, '_blank');
          }}
        >
          В Telegram
        </Button>
      </Box>
    </Paper>
  );
};

export default ModernChannelCard;
