import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { YANDEX_METRIKA_OPTIONS } from '../config/analytics';

/**
 * Компонент для интеграции Яндекс.Метрики
 * @param {Object} props - Свойства компонента
 * @param {string} props.counterId - ID счетчика Яндекс.Метрики
 */
const YandexMetrika = ({ counterId }) => {
  const location = useLocation();

  // Инициализация Яндекс.Метрики при монтировании компонента
  useEffect(() => {
    // Проверяем, что ID счетчика предоставлен
    if (!counterId) {
      console.warn('Не указан ID счетчика Яндекс.Метрики');
      return;
    }

    // Проверяем, что скрипт еще не загружен
    if (window.ym) {
      return;
    }

    // Добавляем скрипт Яндекс.Метрики в head
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `
      (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
      m[i].l=1*new Date();
      for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
      k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
      (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

      ym(${counterId}, "init", ${JSON.stringify(YANDEX_METRIKA_OPTIONS)});
    `;
    document.head.appendChild(script);

    // Добавляем noscript тег для случаев, когда JavaScript отключен
    const noscript = document.createElement('noscript');
    const img = document.createElement('img');
    img.src = `https://mc.yandex.ru/watch/${counterId}`;
    img.style.position = 'absolute';
    img.style.left = '-9999px';
    img.alt = '';
    noscript.appendChild(img);
    document.head.appendChild(noscript);

    // Функция очистки при размонтировании компонента не требуется,
    // так как это может нарушить работу метрики
  }, [counterId]);

  // Отслеживание изменения маршрута для регистрации просмотра страницы
  useEffect(() => {
    if (window.ym && counterId) {
      // Фиксируем просмотр страницы
      window.ym(counterId, 'hit', window.location.pathname + window.location.search, {
        title: document.title
      });
    }
  }, [location, counterId]);

  // Компонент не рендерит никакой UI
  return null;
};

export default YandexMetrika;
