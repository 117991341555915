import React from 'react';
import { Grid } from '@mui/material';
import StatCard from './StatCard';

const StatisticsSection = ({ statistics }) => {
  return (
    <Grid container spacing={3} sx={{ mb: 5 }}>
      {statistics.map((stat, index) => (
        <Grid item xs={6} md={3} key={index}>
          <StatCard stat={stat} index={index} />
        </Grid>
      ))}
    </Grid>
  );
};

export default StatisticsSection;