import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SecurityIcon from '@mui/icons-material/Security';

/**
 * Компонент для отображения ошибок безопасности
 * @param {Object} props - Свойства компонента
 * @param {boolean} props.open - Флаг открытия диалога
 * @param {Function} props.onClose - Функция закрытия диалога
 * @param {string} props.errorType - Тип ошибки безопасности
 * @param {string} props.message - Сообщение об ошибке
 * @returns {JSX.Element}
 */
const SecurityErrorDialog = ({ open, onClose, errorType = 'csrf', message }) => {
  const navigate = useNavigate();

  // Определяем заголовок и сообщение в зависимости от типа ошибки
  const getErrorDetails = () => {
    switch (errorType) {
      case 'csrf':
        return {
          title: 'Ошибка CSRF-токена',
          message: message || 'Возникла проблема с безопасностью запроса. Пожалуйста, обновите страницу и попробуйте снова.'
        };
      case 'unauthorized':
        return {
          title: 'Доступ запрещен',
          message: message || 'У вас нет прав для выполнения этого действия. Пожалуйста, войдите в систему с соответствующими правами.'
        };
      case 'session_expired':
        return {
          title: 'Сессия истекла',
          message: message || 'Ваша сессия истекла. Пожалуйста, войдите в систему снова.'
        };
      default:
        return {
          title: 'Ошибка безопасности',
          message: message || 'Возникла проблема с безопасностью. Пожалуйста, обновите страницу и попробуйте снова.'
        };
    }
  };

  const errorDetails = getErrorDetails();

  // Обработчик перенаправления на страницу входа
  const handleLogin = () => {
    onClose();
    navigate('/login');
  };

  // Обработчик обновления страницы
  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="security-error-dialog-title"
      aria-describedby="security-error-dialog-description"
    >
      <DialogTitle id="security-error-dialog-title" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <SecurityIcon color="error" />
        <Typography variant="h6">{errorDetails.title}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="security-error-dialog-description">
          {errorDetails.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Закрыть
        </Button>
        {errorType === 'session_expired' || errorType === 'unauthorized' ? (
          <Button onClick={handleLogin} color="primary" variant="contained">
            Войти
          </Button>
        ) : (
          <Button onClick={handleRefresh} color="primary" variant="contained">
            Обновить страницу
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default SecurityErrorDialog;
