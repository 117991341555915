import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';

/**
 * Компонент для отображения мета-тегов SEO
 * Принимает объект с метаданными или функцию для их получения
 * @component
 */
const MetaTags = ({ 
  metadata = null, 
  fetchMetadata = null, 
  defaultTitle = 'TeleHunt - каталог Telegram каналов',
  defaultDescription = 'Найдите лучшие Telegram каналы в нашем каталоге'
}) => {
  const [seoData, setSeoData] = useState(metadata || null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const fetchTimeoutRef = useRef(null);
  const isMountedRef = useRef(true);

  // Загрузка метаданных при монтировании компонента
  useEffect(() => {
    // Устанавливаем флаг монтирования
    isMountedRef.current = true;
    
    // Если переданы готовые метаданные, используем их
    if (metadata) {
      setSeoData(metadata);
      return;
    }

    // Если передана функция для получения метаданных, вызываем её
    if (fetchMetadata) {
      // Используем requestIdleCallback для загрузки в свободное время
      const idleCallback = window.requestIdleCallback || ((cb) => setTimeout(cb, 1));
      
      idleCallback(() => {
        if (!isMountedRef.current) return;
        
        const loadMetadata = async () => {
          try {
            setIsLoading(true);
            setError(null);
            
            // Устанавливаем таймаут для загрузки SEO-метаданных
            const timeoutPromise = new Promise((_, reject) => {
              fetchTimeoutRef.current = setTimeout(() => {
                reject(new Error('Таймаут при загрузке SEO-метаданных'));
              }, 3000); // 3 секунды таймаут
            });
            
            // Запускаем запрос и таймаут параллельно
            const data = await Promise.race([
              fetchMetadata(),
              timeoutPromise
            ]);
            
            // Очищаем таймаут
            if (fetchTimeoutRef.current) {
              clearTimeout(fetchTimeoutRef.current);
              fetchTimeoutRef.current = null;
            }
            
            // Проверяем, что получены необходимые данные
            if (!data || (!data.title && !data.description)) {
              setError(new Error('Получены некорректные SEO-метаданные'));
            } else if (isMountedRef.current) {
              setSeoData(data);
            }
          } catch (err) {
            if (isMountedRef.current) {
              setError(err);
              console.warn('[Ошибка] MetaTags - Ошибка при загрузке SEO-метаданных:', err.message);
            }
          } finally {
            if (isMountedRef.current) {
              setIsLoading(false);
            }
          }
        };

        loadMetadata();
      });
    }
    
    // Очищаем при размонтировании
    return () => {
      isMountedRef.current = false;
      if (fetchTimeoutRef.current) {
        clearTimeout(fetchTimeoutRef.current);
        fetchTimeoutRef.current = null;
      }
    };
  }, [metadata, fetchMetadata]);

  // Подготавливаем значения для метатегов
  const titleValue = seoData?.title || defaultTitle;
  const descriptionValue = seoData?.description || defaultDescription;
  
  // Проверяем и корректируем канонический URL
  let canonicalUrl = seoData?.canonicalUrl;
  if (canonicalUrl) {
    // Получаем текущий хост фронтенда
    const currentHost = window.location.host;
    const currentProtocol = window.location.protocol;
    
    // Проверяем, соответствует ли хост в канонической ссылке текущему хосту
    try {
      const urlObj = new URL(canonicalUrl);
      if (urlObj.host !== currentHost) {
        urlObj.host = currentHost;
        urlObj.protocol = currentProtocol;
        canonicalUrl = urlObj.toString();
      }
    } catch (error) {
      // Ошибка при обработке канонического URL
      console.warn('[Ошибка] MetaTags - Ошибка при обработке канонического URL:', error.message);
    }
  }

  return (
    <Helmet>
      {/* Базовые метатеги */}
      <title>{titleValue}</title>
      <meta name="description" content={descriptionValue} />
      {seoData?.keywords && <meta name="keywords" content={seoData.keywords} />}
      
      {/* Канонический URL */}
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      
      {/* Open Graph метатеги */}
      {seoData?.ogTitle && <meta property="og:title" content={seoData.ogTitle} />}
      {seoData?.ogDescription && <meta property="og:description" content={seoData.ogDescription} />}
      {seoData?.ogImage && <meta property="og:image" content={seoData.ogImage} />}
      {seoData?.ogType && <meta property="og:type" content={seoData.ogType} />}
      {seoData?.ogUrl && <meta property="og:url" content={seoData.ogUrl} />}
      
      {/* Twitter метатеги */}
      {seoData?.twitterCard && <meta name="twitter:card" content={seoData.twitterCard} />}
      {seoData?.twitterTitle && <meta name="twitter:title" content={seoData.twitterTitle} />}
      {seoData?.twitterDescription && <meta name="twitter:description" content={seoData.twitterDescription} />}
      {seoData?.twitterImage && <meta name="twitter:image" content={seoData.twitterImage} />}
      
      {/* Структурированные данные */}
      {seoData?.schema && (
        <script type="application/ld+json">
          {JSON.stringify(seoData.schema)}
        </script>
      )}
    </Helmet>
  );
};

MetaTags.propTypes = {
  metadata: PropTypes.object,
  fetchMetadata: PropTypes.func,
  defaultTitle: PropTypes.string,
  defaultDescription: PropTypes.string
};

export default MetaTags;
