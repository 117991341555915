import React from 'react';
import { CircularProgress, Box } from '@mui/material';

/**
 * Компонент загрузки
 * @param {Object} props - Свойства компонента
 * @param {string} props.size - Размер индикатора загрузки
 * @param {string} props.color - Цвет индикатора загрузки
 */
const Loader = ({ size = 40, color = 'primary' }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2 }}>
      <CircularProgress size={size} color={color} />
    </Box>
  );
};

export default Loader;
