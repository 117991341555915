import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Button, 
  IconButton, 
  Tooltip,
  alpha,
  CircularProgress
} from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import VerifiedIcon from '@mui/icons-material/Verified';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ProgressiveImage from '../ProgressiveImage';
import DetailStats from './DetailStats';
import { getAvatarUrl, checkImageUrl, getOptimalAvatarSize, migrateLegacyAvatarPath } from '../../utils/mediaUtils';

/**
 * Компонент шапки для страницы деталей канала
 * 
 * @param {Object} props - Свойства компонента
 * @param {Object} props.channel - Данные канала
 * @param {Function} props.onShareClick - Функция для открытия меню шаринга
 * @returns {JSX.Element} Компонент шапки канала
 */
const DetailHeader = ({ channel, onShareClick }) => {
  const [imageError, setImageError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Получаем оптимальный размер аватара для детальной страницы
  const avatarUrl = channel.avatar 
    ? getAvatarUrl(migrateLegacyAvatarPath(channel.avatar), getOptimalAvatarSize('detail')) 
    : null;

  useEffect(() => {
    if (!avatarUrl) {
      setImageError(true);
      setIsLoading(false);
      return;
    }

    // Проверяем доступность изображения
    const validateImage = async () => {
      const isValid = await checkImageUrl(avatarUrl);
      setImageError(!isValid);
      setIsLoading(false);
    };

    validateImage();
  }, [avatarUrl]);

  return (
    <Box sx={{ display: 'flex', gap: 3, mb: 4, flexDirection: { xs: 'column', sm: 'row' } }}>
      {/* Аватар */}
      <Box sx={{ position: 'relative', alignSelf: { xs: 'center', sm: 'flex-start' } }}>
        <Box
          sx={{
            position: 'relative',
            width: { xs: 160, sm: 140 },
            height: { xs: 160, sm: 140 },
            borderRadius: 4,
            overflow: 'hidden',
            boxShadow: theme => `0 8px 24px ${alpha(theme.palette.primary.main, 0.15)}`,
            border: theme => `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
            transition: 'all 0.3s ease-in-out',
            '&:hover': {
              transform: 'translateY(-4px) scale(1.02)',
              boxShadow: theme => `0 12px 32px ${alpha(theme.palette.primary.main, 0.2)}`
            },
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme => alpha(theme.palette.background.paper, 0.8)
          }}
        >
          {isLoading ? (
            <CircularProgress size={40} color="primary" />
          ) : imageError ? (
            <Typography 
              variant="h3" 
              sx={{ 
                fontWeight: 'bold', 
                color: theme => alpha(theme.palette.text.primary, 0.7)
              }}
            >
              {channel.name ? channel.name[0].toUpperCase() : '?'}
            </Typography>
          ) : (
            <ProgressiveImage
              src={avatarUrl}
              alt={channel.name}
              onLoad={() => setIsLoading(false)}
              onError={() => setImageError(true)}
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                transition: 'transform 0.3s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.05)'
                }
              }}
            />
          )}
        </Box>
      </Box>
      
      {/* Основная информация */}
      <Box sx={{ flex: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
          <Typography variant="h4" component="h1" sx={{ 
            fontWeight: 700,
            fontSize: { xs: '1.5rem', sm: '2rem' },
            lineHeight: 1.3,
            letterSpacing: '0.01em',
            WebkitFontSmoothing: 'antialiased',
            MozOsxFontSmoothing: 'grayscale',
            textRendering: 'optimizeLegibility'
          }}>
            {channel.name}
          </Typography>
          {channel.isVerified && (
            <Tooltip title="Проверенный канал">
              <VerifiedIcon color="primary" />
            </Tooltip>
          )}
        </Box>

        {/* Статистика */}
        <DetailStats channel={channel} />

        {/* Кнопки */}
        <Box sx={{ 
          display: 'flex', 
          gap: 2,
          flexDirection: { xs: 'row', sm: 'row' },
          alignItems: 'center'
        }}>
          <IconButton 
            onClick={onShareClick}
            sx={{
              width: { xs: 48, sm: 48 },
              height: { xs: 48, sm: 48 },
              border: theme => `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
              transition: 'all 0.3s ease-in-out',
              '&:hover': {
                bgcolor: theme => alpha(theme.palette.primary.main, 0.1),
                transform: 'rotate(15deg)'
              },
              '&:active': {
                transform: 'rotate(0deg)'
              }
            }}
          >
            <ShareIcon sx={{ fontSize: 24 }} />
          </IconButton>
          <Button
            variant="contained"
            size="large"
            endIcon={<OpenInNewIcon />}
            href={channel.link}
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              py: 1.2,
              px: 3,
              flex: { xs: 1, sm: 'none' },
              borderRadius: 2,
              background: theme => `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
              boxShadow: theme => `0 4px 12px ${alpha(theme.palette.primary.main, 0.3)}`,
              transition: 'all 0.3s ease-in-out',
              fontSize: { xs: '0.95rem', sm: '1rem' },
              fontWeight: 600,
              letterSpacing: '0.5px',
              WebkitFontSmoothing: 'antialiased',
              MozOsxFontSmoothing: 'grayscale',
              '&:hover': {
                transform: 'translateY(-2px)',
                boxShadow: theme => `0 8px 20px ${alpha(theme.palette.primary.main, 0.4)}`,
                background: theme => `linear-gradient(45deg, ${theme.palette.primary.light}, ${theme.palette.primary.main})`
              }
            }}
          >
            {/* Разный текст для мобильной и десктопной версии */}
            <Box component="span" sx={{ display: { xs: 'inline', sm: 'none' } }}>
              В TELEGRAM
            </Box>
            <Box component="span" sx={{ display: { xs: 'none', sm: 'inline' } }}>
              Открыть в Telegram
            </Box>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default DetailHeader;
