import React, { useMemo, useCallback } from 'react';
import { 
  Box, 
  Typography, 
  Card, 
  CardContent, 
  List, 
  ListItem, 
  ListItemAvatar, 
  ListItemText, 
  Avatar, 
  Divider,
  Skeleton,
  alpha,
  useTheme
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { formatNumber } from '../../utils/formatters';
import ProgressiveImage from '../ProgressiveImage';
import { getChannelUrl } from '../../services/urlService';
import { getAvatarUrl, migrateLegacyAvatarPath, getOptimalAvatarSize } from '../../utils/mediaUtils';

/**
 * Компонент для отображения рекомендованных каналов
 * @param {Object} props - Свойства компонента
 * @param {Array} props.channels - Список рекомендованных каналов
 * @param {boolean} props.isLoading - Флаг загрузки данных
 * @param {number} props.maxItems - Максимальное количество отображаемых каналов
 */
const RecommendedChannels = ({ 
  channels = [], 
  isLoading = false, 
  maxItems = 5
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  
  // Обработчик клика по каналу
  const handleChannelClick = useCallback((e, channelUrl) => {
    e.preventDefault(); // Предотвращаем стандартное поведение ссылки
    console.log('Переход на канал:', channelUrl);
    // Используем window.location для прямого перехода вместо navigate
    window.location.href = channelUrl;
  }, []);
  
  // Ограничиваем количество отображаемых каналов
  const displayChannels = useMemo(() => {
    return channels?.slice(0, maxItems) || [];
  }, [channels, maxItems]);
  
  // Количество скелетонов для отображения при загрузке
  const skeletonCount = maxItems;

  // Если идет загрузка, показываем скелетон
  if (isLoading) {
    return (
      <Card sx={{ 
        height: '100%',
        borderRadius: 2,
        background: theme => `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.02)} 0%, ${alpha(theme.palette.primary.light, 0.05)} 100%)`,
        border: theme => `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
        overflow: 'hidden',
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
          boxShadow: theme => `0 8px 32px ${alpha(theme.palette.primary.main, 0.1)}`,
          borderColor: theme => alpha(theme.palette.primary.main, 0.2)
        }
      }}>
        <CardContent sx={{ p: { xs: 1.75, sm: 1.75 } }}>
          <Typography variant="h6" sx={{ mb: 1.5, fontWeight: 600 }}>
            Рекомендуемые каналы
          </Typography>
          <List sx={{ p: 0 }}>
            {Array(skeletonCount).fill(0).map((_, index) => (
              <Box key={index} sx={{ py: 1, px: 0 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ 
                    mr: 1.5,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                    <Skeleton 
                      variant="rounded" 
                      width={40} 
                      height={40} 
                      sx={{ 
                        borderRadius: 1.5,
                        bgcolor: theme => alpha(theme.palette.primary.main, 0.1)
                      }} 
                    />
                  </Box>
                  <Box sx={{ width: '100%' }}>
                    <Skeleton variant="text" width="70%" height={20} sx={{ mb: 0.5, bgcolor: theme => alpha(theme.palette.primary.main, 0.1) }} />
                    <Skeleton variant="text" width="40%" height={16} sx={{ bgcolor: theme => alpha(theme.palette.primary.main, 0.1) }} />
                  </Box>
                </Box>
                {index < skeletonCount - 1 && (
                  <Divider sx={{ mt: 1 }} />
                )}
              </Box>
            ))}
          </List>
        </CardContent>
      </Card>
    );
  }

  // Если нет каналов для отображения, не показываем блок
  if (!displayChannels || displayChannels.length === 0) {
    return null;
  }

  return (
    <Card sx={{ 
      height: '100%',
      borderRadius: 2,
      background: theme => `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.02)} 0%, ${alpha(theme.palette.primary.light, 0.05)} 100%)`,
      border: theme => `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
      overflow: 'hidden',
      transition: 'all 0.3s ease-in-out',
      '&:hover': {
        boxShadow: theme => `0 8px 32px ${alpha(theme.palette.primary.main, 0.1)}`,
        borderColor: theme => alpha(theme.palette.primary.main, 0.2)
      }
    }}>
      <CardContent sx={{ p: { xs: 1.75, sm: 1.75 } }}>
        <Typography variant="h6" sx={{ mb: 1.5, fontWeight: 600 }}>
          Рекомендуемые каналы
        </Typography>
        <List sx={{ p: 0 }}>
          {displayChannels.map((channel, index) => {
            // Формируем URL канала с использованием urlService
            const channelUrl = getChannelUrl(channel);
            
            return (
              <React.Fragment key={channel._id}>
                <Box sx={{ py: 1, px: 0 }}>
                  <Box 
                    sx={{ 
                      display: 'flex', 
                      alignItems: 'center',
                      borderRadius: 2,
                      transition: 'all 0.2s ease-in-out',
                      cursor: 'pointer',
                      '&:hover': {
                        bgcolor: alpha(theme.palette.primary.main, 0.05),
                      }
                    }}
                    onClick={(e) => handleChannelClick(e, channelUrl)}
                  >
                    <Box sx={{ 
                      mr: 1.5,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                      <Avatar 
                        variant="rounded"
                        sx={{ 
                          width: 40, 
                          height: 40, 
                          borderRadius: 1.5,
                          bgcolor: theme => alpha(theme.palette.primary.main, 0.2),
                          boxShadow: theme => `0 4px 8px ${alpha(theme.palette.primary.main, 0.15)}`,
                          transition: 'all 0.3s ease-in-out',
                          '&:hover': {
                            transform: 'scale(1.05)',
                            boxShadow: theme => `0 6px 12px ${alpha(theme.palette.primary.main, 0.2)}`
                          }
                        }}
                      >
                        {channel.avatar ? (
                          <ProgressiveImage
                            src={getAvatarUrl(channel.avatar, getOptimalAvatarSize('card'))}
                            alt={channel.name}
                            sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
                          />
                        ) : (
                          channel.name.charAt(0).toUpperCase()
                        )}
                      </Avatar>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      <Typography 
                        variant="subtitle2" 
                        sx={{ 
                          fontWeight: 600,
                          fontSize: '0.85rem',
                          lineHeight: 1.2,
                          mb: 0.5,
                          width: '70%'
                        }}
                      >
                        {channel.name}
                      </Typography>
                      <Typography 
                        variant="body2" 
                        color="text.secondary"
                        sx={{ 
                          fontSize: '0.75rem',
                          lineHeight: 1.2,
                          width: '100%'
                        }}
                      >
                        {formatNumber(channel.subscribers)} подписчиков
                      </Typography>
                    </Box>
                  </Box>
                  {index < displayChannels.length - 1 && (
                    <Divider sx={{ mt: 1 }} />
                  )}
                </Box>
              </React.Fragment>
            );
          })}
        </List>
      </CardContent>
    </Card>
  );
};

export default RecommendedChannels;
