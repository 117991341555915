import React from 'react';
import { Grid, Box, Typography, Chip } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import CategoryIcon from '@mui/icons-material/Category';
import { formatNumber } from '../../utils/formatters';
import { getCategoryUrl } from '../../services/categoryService';

// Компонент для отображения количества каналов с одинаковой толщиной шрифта
const ChannelCount = ({ count }) => {
  const formattedCount = formatNumber(count);
  
  return (
    <Box component="span" sx={{ display: 'inline', fontWeight: 550 }}>
      {formattedCount} каналов
    </Box>
  );
};

// Изменен только параметр с popularCategories на categories для соответствия с Home.js
const PopularCategoriesSection = ({ categories, onCategoryClick }) => {
  return (
    <>
      <Box 
        sx={{ 
          py: 4,
          px: { xs: 2, sm: 0 }, 
          display: { xs: 'none', sm: 'block' }  
        }}
      >
        <Box 
          sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            mb: 3,
            pb: 2,
            borderBottom: '1px solid #e0e0e0'
          }}
        >
          <CategoryIcon sx={{ color: '#0088cc', mr: 1 }} />
          <Typography variant="h5" sx={{ color: '#1e293b', fontWeight: 600 }}>
            Популярные категории
          </Typography>
        </Box>
        
        <Grid container spacing={2} sx={{ mb: 5 }}>
          {categories.map((category, index) => (
            <Grid item xs={6} md={2} key={index}>
              <Box
                component={RouterLink}
                to={getCategoryUrl(category.name)}
                onClick={() => onCategoryClick && onCategoryClick(category)}
                sx={{
                  display: 'block',
                  p: 2,
                  textAlign: 'center',
                  backgroundColor: '#f8f9fa',
                  border: '1px solid #e0e0e0',
                  borderRadius: 2,
                  cursor: 'pointer',
                  textDecoration: 'none',
                  transition: 'all 0.2s ease-in-out',
                  '&:hover': {
                    borderColor: '#1a237e',
                    backgroundColor: 'white',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    transform: 'translateY(-2px)'
                  }
                }}
              >
                <category.icon sx={{ 
                  fontSize: 36, 
                  color: '#1a237e', 
                  mb: 1, 
                  mt: 2,
                  transition: 'all 0.2s ease-in-out',
                  '&:hover': {
                    transform: 'scale(1.1)'
                  }
                }} />
                <Typography variant="subtitle1" sx={{ fontWeight: 500, mb: 1, color: '#2c3e50' }}>
                  {category.name}
                </Typography>
                <Chip 
                  label={<ChannelCount count={category.count} />}
                  size="small"
                  sx={{ 
                    backgroundColor: '#e3f2fd',
                    color: 'rgba(26, 35, 126, 0.85)', 
                    fontWeight: 500,
                    '& .MuiChip-label': { px: 1 }
                  }}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default PopularCategoriesSection;