import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { 
  Box, 
  Typography, 
  Grid, 
  Paper,
  Button
} from '@mui/material';

import CategoryIcon from '@mui/icons-material/Category';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import UpdateIcon from '@mui/icons-material/Update';
import VerifiedIcon from '@mui/icons-material/Verified';

const CatalogInfoSection = () => {
  return (
    <Box sx={{ px: { xs: 0.5, sm: 3 } }}>
      <Paper 
        elevation={0}
        sx={{
          p: { xs: 2.5, sm: 3 },
          mb: { xs: 4, sm: 5 },
          background: 'linear-gradient(135deg, rgba(0, 136, 204, 0.03) 0%, rgba(0, 136, 204, 0.01) 100%)',
          border: '1px solid rgba(0, 136, 204, 0.08)',
          borderRadius: { xs: '24px', sm: '24px' },
          position: 'relative',
          overflow: 'hidden',
          maxWidth: '100%',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '1px',
            background: 'linear-gradient(90deg, transparent, rgba(0, 136, 204, 0.12), transparent)'
          },
          '&::after': {
            content: '""',
            position: 'absolute',
            top: '10%',
            right: '-5%',
            width: '300px',
            height: '300px',
            background: 'radial-gradient(circle, rgba(0, 136, 204, 0.03) 0%, transparent 70%)',
            borderRadius: '50%',
            zIndex: 0,
            pointerEvents: 'none'
          }
        }}
      >
        <Grid container spacing={{ xs: 3, sm: 2.5 }}>
          <Grid item xs={12} md={6}>
            <Box sx={{ position: 'relative', zIndex: 1 }}>
              <Typography 
                variant="h4" 
                sx={{ 
                  color: '#1e293b', 
                  fontWeight: 700, 
                  mb: { xs: 2.5, sm: 2 },
                  fontSize: { xs: '1.75rem', sm: '1.75rem' },
                  letterSpacing: '-0.02em',
                  lineHeight: 1.2
                }}
              >
                Каталог Telegram каналов
              </Typography>
              <Typography 
                variant="body1" 
                sx={{ 
                  color: '#475569', 
                  mb: { xs: 2.5, sm: 2 },
                  lineHeight: 1.5,
                  fontSize: { xs: '1rem', sm: '1rem' },
                  maxWidth: '460px'
                }}
              >
                Мы собрали лучшие каналы в одном месте, чтобы вы могли быстро находить интересный контент. 
                Все каналы проверены и отсортированы по категориям.
              </Typography>
              <Button
                variant="contained"
                size="large"
                component={RouterLink}
                to="/categories"
                startIcon={<CategoryIcon sx={{ fontSize: { xs: '24px', sm: '20px' } }} />}
                sx={{
                  background: 'linear-gradient(135deg, #0088cc 0%, #0077b3 100%)',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    background: 'linear-gradient(135deg, #0099e6 0%, #0088cc 100%)',
                    transform: 'translateY(-1px)',
                    boxShadow: '0 4px 20px rgba(0, 136, 204, 0.2)'
                  },
                  textTransform: 'none',
                  fontWeight: 600,
                  fontSize: { xs: '1rem', sm: '0.9375rem' },
                  px: { xs: 4, sm: 2.5 },
                  py: { xs: 1.5, sm: 1.25 },
                  borderRadius: { xs: '14px', sm: '10px' },
                  boxShadow: '0 4px 15px rgba(0, 136, 204, 0.15)',
                  minHeight: { sm: '38px' }
                }}
              >
                Смотреть все категории
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sx={{ display: { xs: 'none', sm: 'block' } }}>
            <Grid container spacing={{ xs: 2, sm: 1.5 }}>
              {[
                {
                  icon: <AutoGraphIcon sx={{ fontSize: { xs: '24px', sm: '22px' } }} />,
                  title: 'Топ каналы',
                  description: 'Отслеживаем популярность и качество контента'
                },
                {
                  icon: <FilterAltIcon sx={{ fontSize: { xs: '24px', sm: '22px' } }} />,
                  title: 'Умный поиск',
                  description: 'Находите каналы по темам и интересам'
                },
                {
                  icon: <UpdateIcon sx={{ fontSize: { xs: '24px', sm: '22px' } }} />,
                  title: 'Актуальность',
                  description: 'Регулярно обновляем каталог'
                },
                {
                  icon: <VerifiedIcon sx={{ fontSize: { xs: '24px', sm: '22px' } }} />,
                  title: 'Проверка',
                  description: 'Тщательно модерируем контент'
                }
              ].map((item, index) => (
                <Grid item xs={6} key={index}>
                  <Paper 
                    sx={{
                      p: { xs: 2, sm: 1.75 },
                      backgroundColor: '#fff',
                      border: '1px solid rgba(0, 136, 204, 0.08)',
                      borderRadius: { xs: '16px', sm: '16px' },
                      height: '100%',
                      transition: 'all 0.3s ease',
                      position: 'relative',
                      overflow: 'hidden',
                      '&:hover': {
                        transform: 'translateY(-2px)',
                        boxShadow: '0 8px 30px rgba(0, 136, 204, 0.08)',
                        '& .icon-wrapper': {
                          transform: 'scale(1.1)',
                          background: 'linear-gradient(135deg, rgba(0, 136, 204, 0.15) 0%, rgba(0, 136, 204, 0.05) 100%)'
                        }
                      },
                      '&::before': {
                        content: '""',
                        position: 'absolute',
                        inset: 0,
                        borderRadius: 'inherit',
                        padding: '1px',
                        background: 'linear-gradient(135deg, rgba(0, 136, 204, 0.12), transparent)',
                        WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
                        WebkitMaskComposite: 'xor',
                        maskComposite: 'exclude'
                      }
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: { xs: 1.5, sm: 1.25 } }}>
                      <Box 
                        className="icon-wrapper"
                        sx={{ 
                          display: 'inline-flex',
                          p: { xs: 1.25, sm: 1 },
                          borderRadius: { xs: '12px', sm: '8px' },
                          backgroundColor: 'rgba(0, 136, 204, 0.06)',
                          color: '#0088cc',
                          mr: { xs: 1.5, sm: 1.25 },
                          transition: 'all 0.3s ease'
                        }}
                      >
                        {item.icon}
                      </Box>
                      <Typography 
                        variant="h6" 
                        sx={{ 
                          color: '#1e293b', 
                          fontWeight: 600,
                          fontSize: { xs: '1.125rem', sm: '1rem' },
                          lineHeight: 1.2,
                          letterSpacing: '-0.01em'
                        }}
                      >
                        {item.title}
                      </Typography>
                    </Box>
                    <Typography 
                      variant="body2" 
                      sx={{ 
                        color: '#64748b',
                        fontSize: { xs: '0.875rem', sm: '0.875rem' },
                        lineHeight: 1.4
                      }}
                    >
                      {item.description}
                    </Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default CatalogInfoSection;
