import React, { useState, useEffect } from 'react';
import { Paper, Box, Typography } from '@mui/material';
import { formatNumber } from '../../utils/formatters';

const StatCard = ({ stat, index }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [count, setCount] = useState(0);
  const targetCount = parseInt(stat.count.replace(/,/g, ''));

  useEffect(() => {
    const timeout = setTimeout(() => setIsVisible(true), index * 200);
    return () => clearTimeout(timeout);
  }, [index]);

  useEffect(() => {
    if (isVisible) {
      const duration = 1500;
      const steps = 60;
      const increment = targetCount / steps;
      let current = 0;
      
      const timer = setInterval(() => {
        current += increment;
        if (current >= targetCount) {
          setCount(targetCount);
          clearInterval(timer);
        } else {
          setCount(Math.floor(current));
        }
      }, duration / steps);

      return () => clearInterval(timer);
    }
  }, [isVisible, targetCount]);

  return (
    <Paper
      elevation={0}
      sx={{
        p: { xs: 2, sm: 3 },
        textAlign: 'center',
        backgroundColor: 'white',
        borderRadius: 2,
        position: 'relative',
        overflow: 'hidden',
        opacity: isVisible ? 1 : 0,
        transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
        transition: 'all 0.6s cubic-bezier(0.4, 0, 0.2, 1)',
        border: '1px solid rgba(0, 0, 0, 0.06)',
        '&:hover': {
          transform: 'translateY(-5px)',
          boxShadow: '0 8px 24px rgba(0, 0, 0, 0.1)',
          '& .stat-icon': {
            transform: 'scale(1.1)',
          },
          '& .stat-background': {
            opacity: 0.12,
          }
        }
      }}
    >
      <Box
        className="stat-background"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: stat.gradient,
          opacity: 0.08,
          transition: 'opacity 0.3s ease',
        }}
      />
      
      <stat.icon 
        className="stat-icon"
        sx={{ 
          fontSize: { xs: 32, sm: 40 },
          color: '#1a237e',
          mb: { xs: 1, sm: 1.5 },
          position: 'relative',
          transition: 'transform 0.3s ease',
          opacity: 0.9
        }} 
      />

      <Typography 
        variant="h4" 
        sx={{ 
          fontWeight: 600, 
          color: '#1a237e',
          position: 'relative',
          mb: 1,
          fontSize: { xs: '1.5rem', sm: '2rem' }
        }}
      >
        {formatNumber(count)}
      </Typography>

      <Typography 
        variant="body1" 
        sx={{ 
          color: '#455a64',
          position: 'relative',
          fontSize: { xs: '0.875rem', sm: '0.95rem' }
        }}
      >
        {stat.label}
      </Typography>
    </Paper>
  );
};

export default StatCard;