import React, { memo } from 'react';
import { Box, TextField, IconButton, InputAdornment, Typography, useTheme, useMediaQuery, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import LockIcon from '@mui/icons-material/Lock';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import StarIcon from '@mui/icons-material/Star';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import { useNavigate } from 'react-router-dom';

const SearchSection = ({ searchQuery, setSearchQuery, handleSearch, categories }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Обработчик поиска, если не передан извне
  const handleSearchInternal = (e) => {
    e.preventDefault();
    if (searchQuery && searchQuery.trim()) {
      // Перенаправляем на страницу категории "Все" с параметром поиска
      navigate(`/category/all?search=${encodeURIComponent(searchQuery.trim())}`);
    }
  };

  // Используем переданный обработчик или внутренний
  const onSearch = handleSearch || handleSearchInternal;

  const mainCategories = [
    {
      id: 'leaks',
      label: 'Сливы 18+',
      icon: <WhatshotIcon />,
      path: '/category/slivy-18-plus'
    },
    {
      id: 'erotic',
      label: 'Эротика',
      icon: <StarIcon />,
      path: '/category/erotica'
    },
    {
      id: 'private',
      label: 'Приватные чаты',
      icon: <LockIcon />,
      path: '/category/private-channels'
    }
  ];

  const handleCategoryClick = (category) => {
    navigate(category.path);
  };

  // Оптимизированные стили для мобильной версии
  const mobileStyles = {
    container: {
      background: '#f8f9fa',
      borderRadius: '28px',
      padding: '32px 24px',
      boxShadow: '0 8px 30px rgba(0, 0, 0, 0.03), 0 4px 8px rgba(0, 0, 0, 0.02)',
      position: 'relative',
      overflow: 'hidden',
      border: '1px solid rgba(26, 35, 126, 0.08)',
    },
    title: {
      fontWeight: 600,
      fontSize: '2.4rem',
      color: '#1a237e',
      textAlign: 'left',
      mb: 2,
      lineHeight: 1.1,
      position: 'relative',
      letterSpacing: '-0.01em',
    },
    subtitle: {
      fontSize: '1.15rem',
      fontWeight: 500,
      color: '#455a64',
      opacity: 0.95,
      mb: 4.5,
      textAlign: 'left',
      lineHeight: 1.5,
      letterSpacing: '0.01em'
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: 2.5,
      mt: 2
    },
    button: {
      py: 3,
      px: 3.5,
      backgroundColor: 'white',
      color: '#1a237e',
      fontSize: '1.2rem',
      fontWeight: 600,
      textTransform: 'none',
      borderRadius: '20px',
      border: '1px solid rgba(26, 35, 126, 0.08)',
      boxShadow: '0 6px 20px rgba(0, 0, 0, 0.03)',
      justifyContent: 'flex-start',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        boxShadow: '0 8px 25px rgba(0, 0, 0, 0.05)',
      }
    },
    iconContainer: {
      backgroundColor: 'rgba(26, 35, 126, 0.03)',
      borderRadius: '16px',
      padding: '16px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      '& svg': {
        fontSize: '1.8rem',
        color: '#1a237e'
      }
    }
  };

  // Оптимизированные стили для десктопной версии
  const desktopStyles = {
    container: {
      textAlign: 'center',
      mb: 5,
      py: 8,
      px: 3,
      background: 'linear-gradient(135deg, #0088cc 0%, #1e293b 100%)',
      borderRadius: 2,
      color: 'white',
      position: 'relative',
      overflow: 'hidden',
      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
    },
    title: {
      fontWeight: 700,
      fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
      mb: 2,
      textShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    },
    subtitle: {
      fontSize: { xs: '1rem', sm: '1.1rem', md: '1.25rem' },
      fontWeight: 400,
      opacity: 0.9,
      mb: 5,
      maxWidth: '800px',
      mx: 'auto',
    },
    searchContainer: {
      maxWidth: '700px',
      mx: 'auto',
      mb: 4,
    },
    searchField: {
      backgroundColor: 'white',
      borderRadius: '30px',
      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
      '& .MuiOutlinedInput-root': {
        borderRadius: '30px',
        '& fieldset': {
          borderColor: 'transparent',
        },
        '&:hover fieldset': {
          borderColor: 'transparent',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'transparent',
        },
      },
      '& .MuiInputBase-input': {
        padding: '15px 20px',
        fontSize: '1.1rem',
      },
    },
    categoryContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      gap: 2,
      mt: 3,
    },
    categoryButton: {
      color: 'white',
      borderColor: 'rgba(255, 255, 255, 0.3)',
      '&:hover': {
        borderColor: 'white',
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
      },
    },
  };

  return (
    <Box sx={{ 
      textAlign: 'center', 
      mb: { xs: 1, sm: 5 }, 
      py: { xs: 3.5, sm: 8 },
      px: { xs: 2, sm: 3 },
      mt: { xs: -4, sm: 0 },
      background: {
        xs: '#f8f9fa',
        sm: 'linear-gradient(135deg, #0088cc 0%, #1e293b 100%)'
      },
      borderRadius: { xs: 0, sm: 2 },
      color: 'white',
      position: 'relative',
      overflow: 'hidden',
      boxShadow: { xs: 'none', sm: '0 4px 20px rgba(0, 0, 0, 0.15)' },
      mx: { xs: -2, sm: 0 }
    }}>
      {/* Мобильная версия */}
      {isMobile && (
        <Box sx={mobileStyles.container}>
          <Box sx={{ position: 'relative', zIndex: 1 }}>
            <Typography 
              variant="h1" 
              component="h1" 
              sx={mobileStyles.title}
            >
              Откройте мир Телеграм
            </Typography>

            <Typography 
              variant="subtitle1"
              sx={mobileStyles.subtitle}
            >
              Эксклюзивный контент и приватные каналы в вашем смартфоне
            </Typography>

            <Box sx={mobileStyles.buttonContainer}>
              {mainCategories.map((category) => (
                <Button
                  key={category.id}
                  onClick={() => handleCategoryClick(category)}
                  variant="contained"
                  startIcon={
                    <Box sx={mobileStyles.iconContainer}>
                      {category.icon}
                    </Box>
                  }
                  sx={mobileStyles.button}
                >
                  {category.label}
                </Button>
              ))}
            </Box>
          </Box>
        </Box>
      )}

      {/* Десктопная версия */}
      {!isMobile && (
        <>
          <Typography 
            variant="h1" 
            component="h1" 
            sx={desktopStyles.title}
          >
            Найдите свой канал
          </Typography>
          
          <Typography 
            variant="subtitle1"
            sx={desktopStyles.subtitle}
          >
            Самый большой каталог Telegram каналов, ботов и чатов
          </Typography>
          
          <Box component="form" onSubmit={onSearch} sx={desktopStyles.searchContainer}>
            <TextField
              fullWidth
              placeholder="Поиск каналов, ботов и чатов..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={desktopStyles.searchField}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton 
                      type="submit"
                      aria-label="Поиск"
                      edge="end"
                      sx={{ mr: 0.5 }}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          
          <Box sx={desktopStyles.categoryContainer}>
            {mainCategories.map((category) => (
              <Button
                key={category.id}
                variant="outlined"
                startIcon={category.icon}
                onClick={() => handleCategoryClick(category)}
                sx={desktopStyles.categoryButton}
              >
                {category.label}
              </Button>
            ))}
          </Box>
        </>
      )}
    </Box>
  );
};

// Оптимизация с помощью memo для предотвращения лишних рендеров
export default memo(SearchSection);