import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const ProtectedRoute = ({ children, adminRequired = false }) => {
  const { user } = useAuth();
  
  // Если пользователь не авторизован
  if (!user) {
    console.log('Not authenticated. Redirecting to login...');
    return <Navigate to="/login" replace />;
  }

  // Если требуются права админа, но у пользователя их нет
  if (adminRequired && !user.isAdmin) {
    console.log('Admin access required. Redirecting to home...');
    return <Navigate to="/" replace />;
  }

  return children;
};

export default ProtectedRoute;
