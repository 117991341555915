import React, { useMemo, useCallback } from 'react';
import { 
  Box, 
  Typography, 
  Card, 
  CardContent, 
  Grid, 
  Avatar, 
  Skeleton,
  alpha, 
  useTheme 
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { formatNumber } from '../../utils/formatters';
import ProgressiveImage from '../ProgressiveImage';
import { getChannelUrl } from '../../services/urlService';
import { getAvatarUrl, migrateLegacyAvatarPath, getOptimalAvatarSize } from '../../utils/mediaUtils';

/**
 * Компонент для отображения похожих каналов
 * @param {Object} props - Свойства компонента
 * @param {Array} props.channels - Список похожих каналов
 * @param {boolean} props.isLoading - Флаг загрузки данных
 * @param {number} props.maxItems - Максимальное количество отображаемых каналов
 */
const RelatedChannels = ({ 
  channels = [], 
  isLoading = false, 
  maxItems = 6
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  
  // Обработчик клика по каналу
  const handleChannelClick = useCallback((e, channelUrl) => {
    e.preventDefault(); // Предотвращаем стандартное поведение ссылки
    console.log('Переход на канал:', channelUrl);
    // Используем window.location для прямого перехода вместо navigate
    window.location.href = channelUrl;
  }, []);
  
  // Ограничиваем количество отображаемых каналов
  const displayChannels = useMemo(() => {
    return channels?.slice(0, maxItems) || [];
  }, [channels, maxItems]);
  
  // Количество скелетонов для отображения при загрузке
  const skeletonCount = maxItems;

  // Если идет загрузка, показываем скелетон
  if (isLoading) {
    return (
      <Card sx={{ 
        borderRadius: 2,
        background: theme => `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.02)} 0%, ${alpha(theme.palette.primary.light, 0.05)} 100%)`,
        border: theme => `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
        overflow: 'hidden',
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
          boxShadow: theme => `0 8px 32px ${alpha(theme.palette.primary.main, 0.1)}`,
          borderColor: theme => alpha(theme.palette.primary.main, 0.2)
        }
      }}>
        <CardContent sx={{ p: { xs: 2, sm: 3 } }}>
          <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
            Похожие каналы
          </Typography>
          <Grid container spacing={2}>
            {Array(skeletonCount).fill(0).map((_, index) => (
              <Grid item xs={6} sm={4} md={2} key={index}>
                <Box sx={{ 
                  display: 'flex', 
                  flexDirection: 'column', 
                  alignItems: 'center',
                  textAlign: 'center',
                  p: 1.5,
                  borderRadius: 2,
                  transition: 'all 0.3s ease-in-out',
                }}>
                  <Skeleton 
                    variant="circular" 
                    width={56} 
                    height={56} 
                    sx={{ 
                      mb: 1.5,
                      bgcolor: theme => alpha(theme.palette.primary.main, 0.1)
                    }} 
                  />
                  <Skeleton variant="text" width="80%" sx={{ mb: 0.5, bgcolor: theme => alpha(theme.palette.primary.main, 0.1) }} />
                  <Skeleton variant="text" width="50%" sx={{ bgcolor: theme => alpha(theme.palette.primary.main, 0.1) }} />
                </Box>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    );
  }

  // Если нет каналов для отображения, не показываем блок
  if (!displayChannels || displayChannels.length === 0) {
    return null;
  }

  return (
    <Card sx={{ 
      borderRadius: 2,
      background: theme => `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.02)} 0%, ${alpha(theme.palette.primary.light, 0.05)} 100%)`,
      border: theme => `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
      overflow: 'hidden',
      transition: 'all 0.3s ease-in-out',
      '&:hover': {
        boxShadow: theme => `0 8px 32px ${alpha(theme.palette.primary.main, 0.1)}`,
        borderColor: theme => alpha(theme.palette.primary.main, 0.2)
      }
    }}>
      <CardContent sx={{ p: { xs: 2, sm: 2 } }}>
        <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
          Похожие каналы
        </Typography>
        
        {/* Мобильная версия - горизонтальный скролл */}
        <Box 
          sx={{ 
            display: { xs: 'flex', sm: 'none' },
            gap: 2,
            overflowX: 'auto',
            pb: 2,
            px: 1,
            mx: -1,
            '&::-webkit-scrollbar': {
              height: 6,
              borderRadius: 3
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: alpha(theme.palette.primary.main, 0.05),
              borderRadius: 3
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: alpha(theme.palette.primary.main, 0.2),
              borderRadius: 3,
              '&:hover': {
                backgroundColor: alpha(theme.palette.primary.main, 0.3)
              }
            }
          }}
        >
          {displayChannels.map((channel) => {
            // Формируем URL канала с использованием urlService
            const channelUrl = getChannelUrl(channel);
            
            return (
              <Box 
                key={channel._id}
                component="div"
                onClick={(e) => handleChannelClick(e, channelUrl)}
                sx={{ 
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  minWidth: 100,
                  maxWidth: 120,
                  p: 1.5,
                  borderRadius: 2,
                  textDecoration: 'none',
                  color: 'inherit',
                  transition: 'all 0.3s ease-in-out',
                  cursor: 'pointer',
                  '&:hover': {
                    bgcolor: alpha(theme.palette.primary.main, 0.05),
                    transform: 'translateY(-4px)'
                  }
                }}
              >
                <Avatar 
                  src={getAvatarUrl(channel.avatar, getOptimalAvatarSize('card'))}
                  alt={channel.name}
                  sx={{ 
                    width: 56, 
                    height: 56, 
                    mb: 1.5,
                    bgcolor: theme => alpha(theme.palette.primary.main, 0.2),
                    boxShadow: theme => `0 4px 8px ${alpha(theme.palette.primary.main, 0.15)}`,
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: theme => `0 6px 12px ${alpha(theme.palette.primary.main, 0.2)}`
                    }
                  }}
                >
                  {channel.avatar ? (
                    <ProgressiveImage
                      src={getAvatarUrl(channel.avatar, getOptimalAvatarSize('card'))}
                      alt={channel.name}
                      sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                  ) : (
                    channel.name.charAt(0).toUpperCase()
                  )}
                </Avatar>
                <Typography 
                  variant="subtitle2" 
                  sx={{ 
                    fontWeight: 600,
                    fontSize: '0.875rem',
                    lineHeight: 1.2,
                    mb: 0.5,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    height: '2.4em',
                    WebkitFontSmoothing: 'antialiased',
                    MozOsxFontSmoothing: 'grayscale',
                    textRendering: 'optimizeLegibility'
                  }}
                >
                  {channel.name}
                </Typography>
                <Typography 
                  variant="body2" 
                  color="text.secondary"
                  sx={{ 
                    fontSize: '0.75rem',
                    WebkitFontSmoothing: 'antialiased',
                    MozOsxFontSmoothing: 'grayscale',
                    textRendering: 'optimizeLegibility'
                  }}
                >
                  {formatNumber(channel.subscribers)}
                </Typography>
              </Box>
            );
          })}
        </Box>
        
        {/* Десктопная версия - сетка */}
        <Grid container spacing={2} sx={{ display: { xs: 'none', sm: 'flex' } }}>
          {displayChannels.map((channel) => {
            // Формируем URL канала с использованием urlService
            const channelUrl = getChannelUrl(channel);
            
            return (
              <Grid item xs={6} sm={4} md={2} key={channel._id}>
                <Box 
                  component="div"
                  onClick={(e) => handleChannelClick(e, channelUrl)}
                  sx={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    p: 1.5,
                    borderRadius: 2,
                    textDecoration: 'none',
                    color: 'inherit',
                    transition: 'all 0.3s ease-in-out',
                    cursor: 'pointer',
                    '&:hover': {
                      bgcolor: alpha(theme.palette.primary.main, 0.05),
                      transform: 'translateY(-4px)'
                    }
                  }}
                >
                  <Avatar 
                    src={getAvatarUrl(channel.avatar, getOptimalAvatarSize('card'))}
                    alt={channel.name}
                    sx={{ 
                      width: 56, 
                      height: 56, 
                      mb: 1.5,
                      bgcolor: theme => alpha(theme.palette.primary.main, 0.2),
                      boxShadow: theme => `0 4px 8px ${alpha(theme.palette.primary.main, 0.15)}`,
                      transition: 'all 0.3s ease-in-out',
                      '&:hover': {
                        transform: 'scale(1.05)',
                        boxShadow: theme => `0 6px 12px ${alpha(theme.palette.primary.main, 0.2)}`
                      }
                    }}
                  >
                    {channel.avatar ? (
                      <ProgressiveImage
                        src={getAvatarUrl(channel.avatar, getOptimalAvatarSize('card'))}
                        alt={channel.name}
                        sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
                      />
                    ) : (
                      channel.name.charAt(0).toUpperCase()
                    )}
                  </Avatar>
                  <Typography 
                    variant="subtitle2" 
                    sx={{ 
                      fontWeight: 600,
                      fontSize: '0.875rem',
                      lineHeight: 1.2,
                      mb: 0.5,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: 'vertical',
                      height: '2.4em',
                      WebkitFontSmoothing: 'antialiased',
                      MozOsxFontSmoothing: 'grayscale',
                      textRendering: 'optimizeLegibility'
                    }}
                  >
                    {channel.name}
                  </Typography>
                  <Typography 
                    variant="body2" 
                    color="text.secondary"
                    sx={{ 
                      fontSize: '0.75rem',
                      WebkitFontSmoothing: 'antialiased',
                      MozOsxFontSmoothing: 'grayscale',
                      textRendering: 'optimizeLegibility'
                    }}
                  >
                    {formatNumber(channel.subscribers)}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default RelatedChannels;
